import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import Banner from "../../components/Banner";
import ContentBlock from "../../components/ContentBlock";
import ListBlock from "../../components/ListBlock";
import ScrollContainer from "../../containers/ScrollContainer";
import Products from "../../components/Products";
import SalesModels from "../../components/SalesModels";
import TechnologyInfo from "../../components/TechnologyInfo";
import Contacts from "../../components/Contacts";

import AIOverlay from "../../containers/AIOverlay";
import { ReactComponent as AiAsAServiceSvg } from "../../images/svg/animate/ai-as-a-service.svg";
import fetchQuery from "../../utils/fetch";

class AiAsAService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            data_science: {},
            benefits: {},
            our_products: {},
            it_services: {},
            sales_models: {}
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.service});
    }

    render() {
        const { banner, data_science, benefits, our_products, sales_models, it_services } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <AiAsAServiceSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='3'/>
                    <ContentBlock id='data-science' data={data_science}/>
                    <ListBlock id='benefits' data={benefits}/>
                    <Products id='our-products' data={our_products}/>
                    <TechnologyInfo id='it-services' data={it_services}/>
                    <SalesModels id='sales-models' data={sales_models}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(AiAsAService);
