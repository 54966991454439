import { CHANGE_ACTIVE_LINK, CHANGE_MENU_THEME } from "../actions/MenuActions"

const initialState = {
    id: null,
    theme: '_dark'
};

export function menu(state = initialState, action) {
    switch (action.type) {
        case CHANGE_ACTIVE_LINK: {
            return {...state, id: action.payload};
        }
        case CHANGE_MENU_THEME: {
            return {...state, theme: action.payload};
        }
        default: {
            return state
        }
    }
}
