import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import AIOverlay from "../../containers/AIOverlay";
import ScrollContainer from "../../containers/ScrollContainer";
import Banner from "../../components/Banner";
import Article from "../../components/Article";
import Contacts from "../../components/Contacts";
import { ReactComponent as ArticleSvg } from "../../images/svg/animate/article-page.svg";
import fetchQuery from "../../utils/fetch";

class NewsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            content: {}
        };
    }

    componentDidMount() {
        const { match } = this.props;
    
        return fetchQuery.bind(this)({
            url: urls.article,
            method: 'POST',
            body: JSON.stringify({
                "id": match.params.id
            })
        });
    }

    render() {
        const { banner, content } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <ArticleSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='1'/>
                    <Article id='article' data={content}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(NewsPage);
