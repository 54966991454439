import React from "react";
import { css, keyframes } from "emotion";
import NavItem from "../NavItem";
import ChangeLangOverlay from "../ChangeLangOverlay";
import TutMeeLogo from "../TutMeeLogo";
import Hamburger from "../Hamburger";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import {connect} from "react-redux";
import logo from "../../images/svg/logo_sign.svg";
import logoWhite from "../../images/svg/logo_sign_white.svg";
import Collapse from "@kunukn/react-collapse";
import { changeActiveBlock } from "../../actions/ChangeActiveBlock";
import urls from "../../constants/urls";
import {NavLink} from "react-router-dom";
import fetchQuery from "../../utils/fetch";

const showMenu = keyframes`
    from {
       transform: translate3d(0, 20px, 0);
       opacity: 0;
    }
    
    to {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
`;


const showLine = keyframes`
    from {
       transform: translate3d(0, -101%, 0);
       opacity: 0;
    }
    
    to {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
`;

const showBtn = keyframes`
    from {
       transform: translate3d(0, 20px, 0);
       opacity: 0;
    }
    
    to {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
`;

const css_style = css({
    display: 'block',
    position: 'relative',
    height: '100%',
    width: styles.width.menu1920,
    maxWidth: '100%',
    background: '',
    ...styles.fixed('0', '0', 'auto', 'auto'),
    zIndex: '20',
    overflow: 'visible',
    pointerEvents: 'none',

    '&__wrap': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        padding: '60px 30px',
        pointerEvents: 'auto',
        overflow: 'auto',
        zIndex: '3',

        '&::before': {
            content: '""',
            display: 'block',
            ...styles.absolute('0', 'auto', 'auto'),
            height: '300px',
            width: '1px',
            background: styles.colors.cyan,
            transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1)',
            animation: `${showLine} 1s ease both`,
            animationDelay: `${styles.blocksFadeDuration * 1.5}ms`,
            zIndex: '3'
        },
    },

    '&__btn': {
        position: 'relative',
        width: '47px',
        height: '47px',
        border: `2px solid ${styles.colors.gray_light}`,
        borderRadius: '50%',
        zIndex: '3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...fonts.b2u,
        lineHeight: '1',
        transition: styles.transitions.default,
        color: styles.colors.gray_dark,
        opacity: 0,
        animation: `${showBtn} 1s ease both`,
        animationDelay: `${styles.blocksFadeDuration * 2 + 500}ms`,
        marginTop: '30px',
        flex: '0 0 auto',

        '&:hover': {
            borderColor: styles.colors.cyan
        }
    },

    '&__container': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        opacity: 0,
        animation: `${showMenu} 1s ease both`,
        animationDelay: `${styles.blocksFadeDuration * 2}ms`,
        zIndex: '3'
    },

    '&__logo-wrap': {
        display: 'block',
        position: 'relative',
        width: '55px',
        marginRight: 'auto',
        paddingBottom: '60px',
        zIndex: '3'
    },

    '&__hamburger-wrap': {
        ...styles.absolute('0', '0', 'auto', 'auto'),
        opacity: 0,
        animation: `${showMenu} 1s ease both`,
        animationDelay: `${styles.blocksFadeDuration * 2}ms`,
        zIndex: '5',
    },

    '&__logo-link': {
        display: 'block',
        position: 'relative',
        width: '100%',
        cursor: 'pointer'
    },

    '&__logo': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: 'auto',
        opacity: '0',
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDelay: '0s',
        zIndex: '3'
    },
    
    '&__privacy-wrap': {
        position: 'relative',
        width: '100%',
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '40px'
    },
    
    '.collapse-css-transition': {
        transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1)',
        display: 'block',
        position: 'relative',
        flex: '0 0 auto',
    },
    
    '.logo-collapse-wrap': {
        marginTop: 'auto'
    },
    
    '& &__privacy-wrap + .logo-collapse-wrap': {
        marginTop: '0'
    },

    '&__privacy-link': {
        color: styles.colors.blue,
        ...fonts.b3s,
        marginBottom: '5px',
        transition: styles.transitions.default,
        
        '&:last-child': {
            marginBottom: '0'
        },
        
        '&:hover': {
            color: styles.colors.cyan
        }
    },
    
    '&._full &': {
        '&__logo': {
            opacity: '1',
            transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDelay: '.25s'
        },

        '&__wrap': {
            '&::before': {
                height: '100%'
            }
        }
    },

    '&._light &': {
        '&__wrap': {
            '&::before': {
                background: styles.colors.white
            },
        },

        '&__btn': {
            '&:hover': {
                borderColor: styles.colors.gray_light,
                color: styles.colors.white,
            }
        },
    },

    [styles.bp(1700)]: {
        width: styles.width.menu1700,

        '&__logo-wrap': {
            paddingBottom: '50px',
        },

        '&__btn': {
            width: '40px',
            height: '40px',
        },
    },

    [styles.bp(1300)]: {
        width: styles.width.menu1300,

        '&__logo-wrap': {
            width: '48px',
            paddingBottom: '40px',
        },

        '&__wrap': {
            padding: '60px 20px 20px',
        },

    },

    [styles.bp(1000)]: {
        '&__wrap': {
            top: '-110vh',
            opacity: '0',
            transitionProperty: 'top, opacity',
            transitionDuration: '0s, 2s',
            transitionTimingFunction: 'linear, cubic-bezier(0.18, 1, 0.21, 1)',
            transitionDelay: '2s, 0s',
            background: 'rgba(255, 255, 255, .95)'
        },


        '&._light &': {
            '&__wrap': {
                background: 'rgba(116,200,175, .95)'
            },

        },

        '&._active': {
            pointerEvents: 'auto',
        },

        '&._active &': {
            '&__wrap': {
                top: '0',
                opacity: '1',
                transitionDelay: '0s, 0s',
            }
        },

        '&__logo-wrap': {
            width: '40px',
            paddingBottom: '35px',
        },
    },

    [styles.bp(700)]: {
        '&__btn': {
            width: '35px',
            height: '35px',
            marginTop: '20px'
        },
    }
});

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: false,
            showMenu: false,
            show: false,
            menu: {},
            lang: {}
        };

        this.hamburger = React.createRef();
        this.langOverlay = React.createRef();
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.menu});
    }

    scrollTo = (id) => {
        const { resolution } = this.props;

        if (resolution.isMob) {
            const
                el = document.getElementsByClassName(`js-${id}`)[0],
                offsetTop = el ? el.offsetTop : false;

            if (offsetTop) {
                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
        } else {
            this.props.changeActiveBlock(id);
        }
    };

    renderNav = (menu) => {
        let navArr = [];
        const { theme } = this.props.menu;
        const { location } = this.props;

        for (let i = 0; i < menu.length; i++) {
            const isActive = menu[i].id === this.props.menu.id;
            let hasMatch = false;

            if (menu[i].sub) {
                for (let c=0; c < menu[i].sub.length; c++) {
                    if (menu[i].sub[c].link === location) hasMatch = true;
                }
            }

            navArr.push(
                <NavItem
                    scrollTo={this.scrollTo}
                    key={i}
                    itemObj={menu[i]}
                    active={isActive || hasMatch}
                    theme={theme}
                    hasMatch={hasMatch}
                    clickHandler={this.closeNav}
                />
            )
        }

        return navArr;
    };

    toggleNav = (isActive) => {
        this.setState({
            showMenu: isActive
        });
    };

    closeNav = () => {
        if (this.hamburger.current) this.hamburger.current.closeHamburger();
    };

    showLangOverlay = () => {
        if (this.langOverlay.current) this.langOverlay.current.showOverlay();
    };

    onLogoClick = () => {
        this.closeNav();

        const { resolution } = this.props;

        if (resolution.isMob) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            this.props.changeActiveBlock();
        }
    };

    render() {
        const
            { id, theme } = this.props.menu,
            { menu, lang, showMenu, privacy } = this.state,
            showFull = id && id !== 'banner',
            showTutMee = id && id === 'contact-us' ? '_show' : '',
            isActive = showMenu ? '_active' : '',
            menuMod = showFull ? '_full' : '';

        return (
            <div id="menu" className={`${css_style} ${menuMod} ${theme} ${isActive}`}>
                <div className={`${css_style}__hamburger-wrap`}>
                    <Hamburger ref={this.hamburger} toggleNav={this.toggleNav}/>
                </div>


                <div className={`${css_style}__wrap`}>
                    <Collapse isOpen={showFull}>
                        <div className={`${css_style}__logo-wrap`}>
                            <div onClick={this.onLogoClick} className={`${css_style}__logo-link`}>
                                <img
                                    src={`${theme === '_light' ? logoWhite : logo}`}
                                    className={`${css_style}__logo`}
                                    alt='logo'
                                />
                            </div>
                        </div>
                    </Collapse>

                    <div className={`${css_style}__container`}>
                        {menu && this.renderNav(menu)}
                    </div>
                    
                    {lang && <div className={`${css_style}__btn`} onClick={this.showLangOverlay}>{lang.btn_text}</div>}

                    {privacy &&
                        <div className={`${css_style}__privacy-wrap`}>
                            {
                                privacy.map((item, key) => {
                                    return (
                                        item.type === 'nav'
                                            ?
                                            <NavLink key={key} to={item.link} className={`${css_style}__privacy-link`}>{require("html-react-parser")(item.text)}</NavLink>
                                            :
                                            <a key={key} href={item.link} target='_blank' rel='noopener noreferrer' className={`${css_style}__privacy-link`}>{item.text}</a>
                                    )
                                })
                            }
                        </div>
                    }
                    
                    <TutMeeLogo show={showTutMee}/>
                </div>

                {lang && <ChangeLangOverlay ref={this.langOverlay} theme={theme} data={lang}/>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeActiveBlock: id => dispatch(changeActiveBlock(id)),
    }
};


const mapStateToProps = store => {
    return {
        menu: store.menu,
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu)

