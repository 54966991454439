import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import Banner from "../../components/Banner";
import ContentBlock from "../../components/ContentBlock";
import ScrollContainer from "../../containers/ScrollContainer";
import OurSkills from "../../components/OurSkills";
import Contacts from "../../components/Contacts";

import AIOverlay from "../../containers/AIOverlay";
import { ReactComponent as WhoWeAreSvg } from "../../images/svg/animate/who-we-are.svg";
import fetchQuery from "../../utils/fetch";

class WhoWeAre extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            brief: {},
            values: {},
            skills: {}
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.about});
    }

    render() {
        const { banner, brief, values, skills } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <WhoWeAreSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='5'/>
                    <ContentBlock id='brief' data={brief}/>
                    <ContentBlock id='values' styleMod='_reverse' data={values}/>
                    <OurSkills id='skills' data={skills}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(WhoWeAre);


