import React, { Component } from "react";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import theme from "./mapTheme.json";
import markerSvg from '../../images/svg/place.svg';

export class MapContainer extends Component {
    render() {
        const { coords } = this.props.data;

        return (
            <Map
                google={this.props.google}
                zoom={13}
                fullscreenControl={false}
                initialCenter={{
                    lat: coords[0],
                    lng: coords[1]
                }}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                styles={theme}
            >
                <Marker
                    name={'Your position'}
                    position={{lat: coords[0], lng: coords[1]}}
                    icon={{
                        url: markerSvg
                }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: props.data.api_key
    }
))(MapContainer)