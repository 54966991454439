import React from "react";
import FormDefault from "../../components/FormDefault";
import Title from '../../components/Title';
import TextBlock from '../../components/TextBlock';
import urls from "../../constants/urls";
import { css } from "emotion";
import styles from "../../constants/styles";

const css_style = css({
    display: 'block',
    position: 'relative',
    overflow: 'hidden',

    '&__ty': {
        ...styles.absolute(),
        width: '100%',
        height: '100%',
        background: styles.colors.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        opacity: '0',
        transform: 'translateX(100%)',
        pointerEvents: 'none',
        transition: 'transform 0s linear, opacity 1s ease',
        transitionProperty: 'transform, opacity',
        transitionDelay: '1s, 0s',

        '&._show': {
            opacity: '1',
            pointerEvents: 'auto',
            transform: 'translateX(0)',
            transitionDelay: '0s, 0s',
        }
    }
});

class FormContactsUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tyShow: false
        };
    }

    handleSubmit = (values, actions) => {
        fetch(urls.form_handler, {
            method: 'POST',
            body: JSON.stringify(values),
        })
            .then(() => {
                this.showTy(actions);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    showTy = (actions) => {
        this.setState({
            tyShow: true
        });

        setTimeout(() => {
            actions.resetForm();
        }, 2000);

        setTimeout(() => {
            this.setState({
                tyShow: false
            });
        }, 5000);
    };

    render() {
        const { data, formData, buttonText } = this.props;
        const { tyShow } = this.state;
        const mod = tyShow ? '_show' : '';
        
        return (
            <div className={`${css_style}`}>
                {formData &&
                    <FormDefault data={formData} buttonText={buttonText} handleSubmit={this.handleSubmit}/>
                }

                <div className={`${css_style}__ty ${mod}`}>
                    {data.title &&
                        <Title data={data.title}/>
                    }

                    {data.text_block &&
                        <TextBlock data={data.text_block}/>
                    }
                </div>
            </div>
        );
    }
}

export default FormContactsUs;
