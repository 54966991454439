import React from "react";
import { css } from "emotion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TextBlock from "../TextBlock";
import styles from "../../constants/styles";

const css_style = css({
    position: 'relative',
    display: 'block',
    width: '100%',
    overflow: 'hidden',

    '&__slider': {
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },

    '&__slider:not(.slick-initialized)': {
        opacity: 0
    },

    '&__slider:not(.slick-initialized) &': {
        '&__item:not(:first-of-type)': {
            display: 'none'
        }
    },

    '.slick-slide:nth-of-type(1n) &__item': {
        '&::before': {
            background: styles.colors.cyan
        },
        
        '._square-ul ul li::before': {
            background: styles.colors.cyan
        }
    },

    '.slick-slide:nth-of-type(2n) &__item': {
        '&::before': {
            background: styles.colors.blue
        },
    
        '._square-ul ul li::before': {
            background: styles.colors.blue
        }
    },

    '.slick-slide:nth-of-type(3n) &__item': {
        '&::before': {
            background: styles.colors.green
        },
    
        '._square-ul ul li::before': {
            background: styles.colors.green
        }
    },

    '.slick-slide:nth-of-type(4n) &__item': {
        '&::before': {
            background: styles.colors.orange_light
        },
    
        '._square-ul ul li::before': {
            background: styles.colors.orange_light
        }
    },

    '.slick-slide:nth-of-type(5n) &__item': {
        '&::before': {
            background: styles.colors.orange
        },
    
        '._square-ul ul li::before': {
            background: styles.colors.orange
        }
    },

    '&__item': {
        width: '100%',
        padding: '0 10px',
        outline: 'none',

        '&::before': {
            content: '""',
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '11px',
            background: 'red',
            marginBottom: '50px'
        },
    },

    '.slick-slider': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexWrap: 'wrap'
    },

    '.slick-list': {
        margin: '0 -10px 30px',
        order: '1'
    },

    '.slick-dots': {
        ...styles.slickDots
    },

    '.slick-arrow': {
        position: 'relative',
        display: 'block',
        transform: 'none',
        left: '0',
        right: '0',
        transition: styles.transitions.default,
        height: '54px',
        width: '34px',

        '&.slick-disabled': {
            opacity: '0.4',
            cursor: 'auto',

            '&::before': {
                opacity: '1'
            }
        },

        '&::before, &::after': {
            content: '""',
            ...styles.absolute(),
            height: '54px',
            width: '34px',
            display: 'block',
            boxSizing: 'border-box',
            borderStyle: 'solid',
            borderWidth: '27px 0 27px 34px',
            transition: styles.transitions.default
        },

        '&::before': {
            borderColor: `transparent transparent transparent ${styles.colors.cyan}`,
            zIndex: '1'
        },

        '&::after': {
            borderColor: `transparent transparent transparent ${styles.colors.white}`,
            zIndex: '2',
            transform: 'scale(0.25)',
            transformOrigin: '50% 50%',
            left: '-10%',
            opacity: '1'
        },

        '&:not(.slick-disabled):hover': {
            '&::after': {
                transform: 'scale(0.4)'
            },
        }
    },

    '.slick-prev': {
        transform: 'scaleX(-1)',
        marginRight: '30px',
        order: '2',
    },

    '.slick-next': {
        order: '3',
    },

    '&._show': {

    },

    '&._show &': {

    },

    [styles.bp(1700)]: {
        '&__item': {
            '&::before': {
                marginBottom: '30px'
            },
        },

        '.slick-list': {
            marginBottom: '10px'
        },

        '.slick-arrow': {
            height: '44px',
            width: '28px',

            '&::before, &::after': {
                height: '44px',
                width: '28px',
                borderWidth: '22px 0 22px 28px',
            },
        },
    },

    [styles.bp(1300)]: {
        '&__item': {
            '&::before': {
                height: '8px',
                marginBottom: '25px'
            },
        },
    },

    [styles.bp(700)]: {
        '&__item': {
            '&::before': {
                height: '6px',
                marginBottom: '20px'
            },
        },
    }
});

class ColumnSlider extends React.Component {
    constructor(props) {
        super(props);

        this.defaultSettings = {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: false,
            speed: 500,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: styles.breakpoints[1300],
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true,
                        arrows: false,
                    }
                },
                {
                    breakpoint: styles.breakpoints[700],
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        arrows: false,
                    }
                },
            ]
        }
    }

    render() {
        const { list, customSettings={} } = this.props,
            settings = {...this.defaultSettings, ...customSettings};

        return (
            <div className={`${css_style}`}>
                <Slider className={`${css_style}__slider`} {...settings}>
                    {list &&
                    list.map((item, key) => {
                        return (
                            <div key={key} className={`${css_style}__item`}>
                                {item.text_block &&
                                <TextBlock data={item.text_block}/>
                                }
                            </div>
                        )
                    })
                    }
                </Slider>
            </div>
        )
    }
}

export default ColumnSlider;
