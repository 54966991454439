import React from "react";
import { css } from "emotion";
import Title from "../Title";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";

import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";

import { ReactComponent as Marker1 } from "../../images/svg/animate/markers/marker-1.svg";
import { ReactComponent as Marker2 } from "../../images/svg/animate/markers/marker-2.svg";
import { ReactComponent as Marker3 } from "../../images/svg/animate/markers/marker-3.svg";
import { ReactComponent as Marker4 } from "../../images/svg/animate/markers/marker-4.svg";
import { ReactComponent as Marker5 } from "../../images/svg/animate/markers/marker-5.svg";
import { ReactComponent as Marker6 } from "../../images/svg/animate/markers/marker-6.svg";
import { ReactComponent as Marker7 } from "../../images/svg/animate/markers/marker-7.svg";
import { ReactComponent as Marker8 } from "../../images/svg/animate/markers/marker-8.svg";
import { ReactComponent as Marker9 } from "../../images/svg/animate/markers/marker-9.svg";
import { ReactComponent as Marker10 } from "../../images/svg/animate/markers/marker-10.svg";


const markersArray = [
    <Marker1 />,
    <Marker2 />,
    <Marker3 />,
    <Marker4 />,
    <Marker5 />,
    <Marker6 />,
    <Marker7 />,
    <Marker8 />,
    <Marker9 />,
    <Marker10 />
];

const css_style = css({
    '&__content': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },

    '&__table': {
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },

    '&__col': {

        '&_left': {
            width: '100%'
        },
        '&_right': {
            width: '0'
        }
    },

    '&__list': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        margin: '70px -10px 0'
    },

    '&__item': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'calc(100% / 3 - 20px - 0.1px)',
        margin: '0 10px 50px',
        transform: 'translateY(50px)',
        opacity: '0',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
    },
    '&__svg-wrap': {
        width: '88px',
        height: '88px',
        position: 'relative',
        display: 'block',
        top: '-10px',

        'svg': {
            display: 'block',
            position: 'relative',
            maxHeight: '100%',
            maxWidth: '100%',
        }
    },
    '&__text-wrap': {
        width: 'calc(100% - 88px)',
        paddingLeft: '20px',
        maxWidth: '300px'
    },

    '&__title-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '960px',
        transform: 'translateY(100px)',
        opacity: '0',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
    },

    '&__title': {
        ...fonts.p3,
        marginBottom: '0 !important'
    },

    '&__list._highlight-titles &__title': {
        color: styles.colors.cyan
    },

    '&__text': {
        ...fonts.p5,
        marginBottom: '0',
        marginTop: '10px'
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '100%',
        marginRight: 'auto'
    },

    '&__img': {
        ...styles.absolute('auto', '0', '50px', 'auto'),
        display: 'block',
        maxWidth: '100%',
        maxHeight: '80vh'
    },

    '&._show &': {
        '&__title-wrap': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        },
        '&__item': {
            transition: styles.transitions.type7,
            transform: 'translateY(0)',
            opacity: '1',
            ...styles.setDelays({base: styles.blocksFadeDuration, count: 9, delay: 150})
        }
    },


    '&._img &': {
        '&__col': {
            '&_left': {
                width: '80%'
            },
            '&_right': {
                width: '20%'
            }
        },

        '&__item': {
            width: 'calc(100% / 2 - 20px - 0.1px)'
        },

        '&__text-wrap': {
            maxWidth: '460px'
        },

    },

    'svg path:not([class*="-step1"])': {
        opacity: 0
    },

    [styles.bp(1700)]: {
        '&__list': {
            marginTop: '60px'
        },

        '&__item': {
            marginBottom: '25px'
        },

        '&__svg-wrap': {
            width: '60px',
            height: '60px',
        },

        '&__text-wrap': {
            width: 'calc(100% - 60px)',
        },

        '&._img &': {
            '&__col': {
                '&_left': {
                    width: '85%'
                },
                '&_right': {
                    width: '15%'
                }
            },

            '&__img': {
                maxWidth: 'calc(100% - 40px)'
            },

        },
    },

    [styles.bp(1300)]: {
        '&__list': {
            marginTop: '40px',
            marginLeft: '-5px',
            marginRight: '-5px',
        },

        '&__svg-wrap': {
            width: '55px',
            height: '55px',
        },

        '&__text-wrap': {
            width: 'calc(100% - 55px)',
            paddingLeft: '15px',
        },

        '&__item': {
            width: 'calc(50% - 10px - 0.1px)',
            marginBottom: '20px',
            marginLeft: '5px',
            marginRight: '5px',
        },

        '&._img &': {
            '&__col': {
                '&_left': {
                    width: '100%'
                },
                '&_right': {
                    display: 'none'
                }
            },
        },
    },

    [styles.bp(1000)]: {
        '&__svg-wrap': {
            width: '50px',
            height: '50px',
        },

        '&__text-wrap': {
            width: 'calc(100% - 50px)',
            paddingLeft: '10px',
        },

        '&__item': {
            transitionDelay: `0ms`,
        },

        '&__title-wrap': {
            transitionDelay: `0ms`,
        },


        '&._show &': {
            '&__title-wrap': {
                transitionDelay: `0ms`,
            },
            '&__item': {
                ...styles.setDelays({base: 0, count: 9, delay: 150})
            }
        },
    },


    [styles.bp(700)]: {
        '&__list': {
            marginTop: '20px',
            width: '100%',
            marginLeft: '0',
            marginRight: '0',
        },

        '&__text-wrap': {
            maxWidth: 'none'
        },

        '&__item, &._img &__item': {
            width: '100%',
            marginBottom: '15px',
            marginLeft: '0',
            marginRight: '0',
        },
    },
});



class ListBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();
        this.morphIsInit = false;
        this.timeLinesArray = [];
    }

    componentDidMount() {
        this.svg = this.svgContainer.current;
        this.initMorph();
    }

    componentDidUpdate() {
        const { data, mod } = this.props;

        if (!this.morphIsInit && data.list) {
            this.morphIsInit = true;
            this.initMorph();
        }

        if (this.morphIsInit) {
            if (mod === ' _show') {
                this.startMorph();
            } else {
                this.pauseMorph();
            }
        }
    }

    initMorph = () => {
        const { data, resolution } = this.props;
        const fadeDuration = resolution.isMob ? 1 : styles.blocksFadeDuration;

        if (data.list) {
            const itemsCount = data.list.length;

            for (var i = 1, d = 1; i <= itemsCount * 2; i+=2, d++) {
                const
                    morphFrom = this.svg.querySelector(`.js-object${i}-step1`),
                    morphTo = this.svg.querySelector(`.js-object${i}-step2`),
                    morphFrom2 = this.svg.querySelector(`.js-object${i+1}-step1`),
                    morphTo2 = this.svg.querySelector(`.js-object${i+1}-step2`),
                    fill =  morphTo ? morphTo.getAttribute('fill') : false,
                    fill2 =  morphTo2 ? morphTo2.getAttribute('fill') : false;

                let tl = new TimelineMax({repeat: -1, yoyo: true, repeatDelay: itemsCount / 2, paused: true}),
                    startDelay = (d / 4) + (fadeDuration / 1000);

                if (morphFrom && morphTo) {
                    tl.to(morphFrom, 1.5, {
                        ease: Elastic.easeInOut.config(1, 0.75),
                        morphSVG: morphTo,
                        fill: fill
                    }, `now`).startTime(startDelay);

                    tl.to(morphFrom2, 1.5, {
                        ease: Elastic.easeInOut.config(1, 0.75),
                        morphSVG: morphTo2,
                        fill: fill2
                    }, `now`).startTime(startDelay);
                }

                this.timeLinesArray.push(tl);
            }
        }
    };

    startMorph = () => {
        this.timeLinesArray.forEach((item) => {
            item.play();
        })
    };

    pauseMorph = () => {
        this.timeLinesArray.forEach((item) => {
            item.pause();
        });
    };

    renderList = (list) => {
        let markersCounter = 0;

        return list.map((item, key) => {
            if (markersCounter > markersArray.length - 1) markersCounter = 0;

            return (
                <div key={key} className={`${css_style}__item`}>
                    <div className={`${css_style}__svg-wrap`}>
                        {markersArray[markersCounter++]}
                    </div>
                    <div className={`${css_style}__text-wrap`}>
                        {item.title &&
                        <h6 className={`${css_style}__title`}>
                            {require("html-react-parser")(item.title)}
                        </h6>
                        }
                        {item.text &&
                        <p className={`${css_style}__text`}>
                            {require("html-react-parser")(item.text)}
                        </p>
                        }
                    </div>
                </div>
            )
        })
    };

    render() {
        const { mod, data } = this.props,
            styleMod = data.img ? '_img' : '',
            listMod = data.highlight_list_titles ? '_highlight-titles' : '';

        return (
            <section className={`${css_style} ${styleMod} section ${mod}`}>
                <div className="container">
                    <div className="inner-container">
                        <div className={`${css_style}__content`}>

                            <div className={`${css_style}__title-wrap`}>
                                {data.title &&
                                    <Title data={data.title}/>
                                }
                            </div>

                            <div className={`${css_style}__table`}>
                                <div className={`${css_style}__col ${css_style}__col_left`}>
                                    <div ref={this.svgContainer} className={`${css_style}__list ${listMod}`}>
                                        {data.list &&
                                            this.renderList(data.list)
                                        }
                                    </div>
                                </div>

                                <div className={`${css_style}__col ${css_style}__col_right`}>
                                    <div className={`${css_style}__img-wrap`}>
                                        {data.img && <img src={data.img} className={`${css_style}__img`} alt='service'/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ListBlock;
