import { CHANGE_RESOLUTION } from "../actions/ChangeResolution"
import styles from "../constants/styles";

const initialState = {
    isMob: styles.isMob(),
    isTablet: styles.isTablet(),
};

export function resolution(state = initialState, action) {


    switch (action.type) {
        case CHANGE_RESOLUTION: {
            return {...state, ...action.payload};
        }
        default: {
            return state
        }
    }
}
