import React from "react";
import {connect} from "react-redux";
import styles from "../../constants/styles";
import { css  } from "emotion";

import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";
import Parallax from "../../plugins/parallax/parallax";

const css_style = css({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '10',
    pointerEvents: 'none',

    "&__container": {
        position: 'absolute',
        top: '50%',
        right: '0',
        bottom: 'auto',
        left: '50%',
        width: 'calc(1920px * 1.6625)',
        height: 'calc(980px * 2.4)',
        transform: 'translate(-52.64%,-51.75%)',

        'svg': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%'
        }
    },

    [styles.bp(1700)]: {
        "&__container": {
            width: 'calc(1366px * 1.6625)',
            height: 'calc(700px * 2.4)',
        },
    },

    [styles.bp(1300)]: {
        "&__container": {
            width: 'calc(1000px * 1.6625)',
            height: 'calc(600px * 2.4)',
        },
    }
});

class AIOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();
    }

    componentDidMount() {
        this.svg = this.svgContainer.current;
        this.initParallax();

        this.runAnimation();
    }

    componentDidUpdate() {
        this.runAnimation();
    }

    runAnimation = () => {
        const
            { pageOverlayAI, startBlock } = this.props,
            step = pageOverlayAI.step ? pageOverlayAI.step : startBlock;

        this.animateSVG(step);
    };

    initParallax = () => {
        const parent = this.svg.querySelectorAll('#ai-overlay')[0];

        new Parallax(parent, {
            relativeInput: false,
            invertX:false,
            invertY:false,
            scalarX: 5,
            scalarY: 5
        });
    };

    animateSVG = (step) => {
        let tl = new TimelineMax({
            repeat: 0
        });

        const objectsCount = this.svg.querySelectorAll('[class*="-step0"]').length;

        for (var i = 1; i <= objectsCount; i++) {
            let morphFrom = this.svg.querySelector(`.js-object${i}-step0`),
                morphTo = this.svg.querySelector(`.js-object${i}-${step}`),
                fill = morphTo ? morphTo.getAttribute('fill') : false;

            if (morphFrom) {
                let morphObj = {
                    ease: Elastic.easeInOut.config(1, 0.75)
                };

                morphObj.morphSVG = morphTo ? morphTo : this.svg.querySelector(`.js-object${i}-step0`);
                morphObj.fill = fill ? fill : morphObj.morphSVG.getAttribute('fill');

                tl.to(morphFrom, 2, morphObj, 'now');
            }
        }
    };

    render() {
        return (
            <div className={css_style}>
                <div ref={this.svgContainer} className={`${css_style}__container`}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        pageOverlayAI: store.pageOverlayAI
    }
};

export default connect(
    mapStateToProps
)(AIOverlay)
