import React, { Component } from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";

const css_style = css({
    'h1': {
        ...fonts.h1
    },
    'h2': {
        ...fonts.h2
    },
    'h3': {
        ...fonts.h3
    },
    'h4': {
        ...fonts.h4
    },
    'h5': {
        ...fonts.h5
    },
    'h6': {
        ...fonts.h6
    },
    'p': {
        ...fonts.p5,
        marginBottom: '20px',

        '&:last-child': {
            marginBottom: '0'
        }
    },

    'a': {
        color: styles.colors.cyan,

        '&:hover': {
            textDecoration: 'underline'
        }

    },

    'blockquote': {
        display: 'block',
        position: 'relative',
        paddingLeft: '30px',
        margin: '30px 0',

        '&::before': {
            content: '""',
            display: 'block',
            ...styles.absolute('0', 'auto', 'auto'),
            height: '100%',
            width: '7px',
            background: styles.colors.cyan
        }
    },

    'strong': {
        fontWeight: '700'
    },

    'ul, ol': {
        ...fonts.list
    },

    'ul': {
        ...fonts.ul
    },

    'ol': {
        ...fonts.ol
    },

    '&._triangle-ul': {
        'ul': {
            ...fonts.ult
        },
    },
    
    '&._square-ul': {
        'ul': {
            ...fonts.uls
        },
    },

    '&._2-col-ul': {
        'ul': {
            ...fonts.ul2c
        },
    },

    '&._big-margins': {
        'h5': {
            margin: '50px 0 30px'
        },
        'h6': {
            margin: '20px 0'
        }
    },

    [styles.bp(1700)]: {
        '&._big-margins': {
            'h5': {
                margin: '40px 0 25px'
            },
            'h6': {
                margin: '15px 0'
            }
        }
    },

    [styles.bp(1300)]: {
        '&._big-margins': {
            'h5': {
                margin: '35px 0 20px'
            },
        }
    },

    [styles.bp(700)]: {
        'blockquote': {
            paddingLeft: '20px',
            margin: '20px 0',

            '&::before': {
                width: '5px',
            }
        },

        '&._big-margins': {
            'h5': {
                margin: '20px 0 15px'
            },
        }
    }
});

class TextBlock extends Component {
    render() {
        const { content, mod='' } = this.props.data;

        return (
            <div className={`${css_style} ${mod}`}>
                {require("html-react-parser")(content)}
            </div>
        );
    }
}
export default TextBlock;
