import React from "react";
import {connect} from "react-redux";
import Swipe from 'react-easy-swipe';

import { css } from "emotion";
import styles from "../../constants/styles";

import ScrollWrap from "../../components/ScrollWrap";

import { changeAIStep } from "../../actions/ChangeAIStepActions";
import {changeActiveBlock} from "../../actions/ChangeActiveBlock";
import {changeActiveLink} from "../../actions/MenuActions";
import {changeResolution} from "../../actions/ChangeResolution";
import {PreventScroll} from "../../actions/PreventScroll";

const css_style = css({
    transition: 'opacity 1s ease',
    transitionDelay: '1s',
    opacity: '1',

    '&._fade': {
        transition: 'opacity 0s ease',
        transitionDelay: '0s',
        opacity: '0'
    },

    [styles.bpm(1000)]: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '',
    }
});

class ScrollContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            count: this.props.children.length - 1,
            screensId: this.getScreensId(this.props.children),
            initialScreen: this.props.children[0].props.id,
            activeBlock: 1,
            windowHeight: window.innerHeight,
            fade: false
        };

        this.windowWidth = window.innerWidth;

        this.screensRefs = this.createRefs();
        this.activeBlocks = [];

        this.readyToToggleTimer = null;
        this.readyToToggle = true;

        this.resizeTimer = null;
        this.resizeDelay = 300;

        this.scrollTimer = null;
        this.scrollCheckIsReady = true;
        this.scrollStopDelay = 100;
        this.scrollWhileDelay = 200;
        this.lastScrollPos = 0;
        this.lastScrollDirection = 'up';

        this.swipeY = 0;

        this.mobWrap = React.createRef();
        
        this.ctrlIsActive = false;

        this.onWheelListener = this.onWheelListener.bind(this);
        this.onWheelListener.passive = false;

        if (this.props.scroll.id) {
            const location = this.props.location;

            if (location && !location.hash.length) {
                this.props.changeActiveBlock(this.state.initialScreen);
            }
        }

        this.initResizeListener();
        this.initScrollListener();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("keydown", this.onKeyDown);
    }

    componentDidMount() {
        this.initArrowsEvents();

        if (this.props.resolution.isMob) this._checkInWindow();
        this.hashScroll();
    }

    componentDidUpdate(prevProps) {
        const { scroll, resolution, PreventScroll } = this.props;
        const resolutionChanged = (resolution.isMob !== prevProps.resolution.isMob) || (resolution.isTablet !== prevProps.resolution.isTablet);
        
        if (!scroll.id) {
            this._preventScreenToggle();
        }

        if (scroll.id !== prevProps.scroll.id) {
            this.props.changeStep(scroll.id);
        }

        if ((resolution.isMob !== prevProps.resolution.isMob) && resolution.isMob) {
            this._checkInWindow();
        }

        if (resolutionChanged) {
            PreventScroll(false)
        }
    }

    hashScroll = () => {
        const
            location = this.props.location,
            id = location ? location.hash.replace('#', '') : null;

        if (location && location.hash.length) {
            if (this.props.resolution.isMob) {
                const hashComponent = this.screensRefs[id].current;

                if (hashComponent) {
                    setTimeout(() => {
                        const
                            wrap = this.mobWrap.current,
                            fixOffsetVal = wrap ? wrap.offsetTop : 0;

                        window.scrollTo({
                            top: hashComponent.offsetTop - fixOffsetVal,
                            behavior: "smooth"
                        });

                    }, styles.blocksFadeDuration + 250);
                }
            } else {
                this.props.changeActiveLink(id);
                this.props.changeActiveBlock(id);
            }
        }
    };

    getScreensId = (screens) => {
        return screens.map((item) => {
            return item.props.id
        });
    };

    createRefs = () => {
        const { screensId } = this.state;
        let obj = {};

        for (let i = 0; i < screensId.length; i++) {
            obj[screensId[i]] = React.createRef();
        }

        return obj;
    };

    initScrollListener = () => {
        window.removeEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.handleScroll);
    };

    initArrowsEvents = () => {
        window.removeEventListener("keydown", this.onKeyDown);
        window.addEventListener("keydown", this.onKeyDown);
        
        window.removeEventListener("keyup", this.onKeyUp);
        window.addEventListener("keyup", this.onKeyUp);
    };

    handleScroll = () => {
        if (this.props.resolution.isMob) this._scrollTimer();
    };

    _scrollTimer = () => {
        clearTimeout(this.scrollTimer);

        this.scrollTimer = setTimeout(() =>  {
            this._checkInWindow();
        }, this.scrollStopDelay);

        if (this.scrollCheckIsReady) {
            this.scrollCheckIsReady = false;
            this._checkInWindow();

            setTimeout(() => {
                this.scrollCheckIsReady = true;
            }, this.scrollWhileDelay)
        }
    };

    _checkInWindow = () => {
        const { windowHeight } = this.state;
        let blocksInWindow = [],
            currentScrollDirection =  window.pageYOffset <= this.lastScrollPos ? 'up' : 'down';

        for (var prop in this.screensRefs) {
            if( this.screensRefs.hasOwnProperty( prop ) ) {
                const screen = this.screensRefs[prop];

                if (screen.current) {
                    let blockOffsetTop = screen.current.offsetTop,
                        blockOffsetBottom = blockOffsetTop + screen.current.clientHeight;

                    if (!(blockOffsetBottom < window.pageYOffset || blockOffsetTop > (windowHeight + window.pageYOffset))) {
                        blocksInWindow.push(prop);
                    }
                }
            }
        }

        if ((blocksInWindow.toString() !== this.activeBlocks.toString()) || (currentScrollDirection !== this.lastScrollDirection)) {
            const activeId = currentScrollDirection === 'up' ? blocksInWindow[0] : blocksInWindow[blocksInWindow.length - 1];

            this.activeBlocks = blocksInWindow;
            this.props.changeActiveLink(activeId);
            this.props.changeActiveBlock(activeId);
        }

        this.lastScrollDirection = currentScrollDirection;
        this.lastScrollPos = window.pageYOffset;
    };

    initResizeListener = () => {
        window.onresize = () => {
            if (!this.state.fade && (this.windowWidth !== window.innerWidth)) {
                this.setState({
                    fade: true
                });
            }

            clearTimeout(this.resizeTimer);

            this.resizeTimer = setTimeout(() => {
                this.setState({
                    fade: false
                });
                this._switchMod();
            }, this.resizeDelay);

            this.windowWidth = window.innerWidth;
        }
    };

    _switchMod = () => {
        const
            { resolution, changeResolution } = this.props,
            isMob = styles.isMob(),
            isTablet = styles.isTablet(),
            isDesktop = !isMob && !isTablet;

        if (isDesktop && (resolution.isMob || resolution.isTablet)) {
            changeResolution({
                isMob: false,
                isTablet: false
            });
        }

        else if (isTablet && !resolution.isTablet) {
            changeResolution({
                isMob: false,
                isTablet: true
            });
        }

        else if (isMob && !resolution.isMob) {
            changeResolution({
                isMob: true,
                isTablet: false
            });
        }

        else {
            this.setState({
                windowHeight: window.innerHeight
            });
        }
    };
    
    onKeyUp = (e) => {
        if (e.keyCode === 17) {
            this.ctrlIsActive = false;
        }
    };
    
    onKeyDown = (e) => {
        if ((e.target.nodeName !== 'INPUT') && (e.target.nodeName !== 'TEXTAREA')) {
            var keyCode = e.keyCode,
                isPrev = keyCode === 37 || keyCode === 38,
                isNext = keyCode === 39 || keyCode === 40,
                direction = isNext ? "next" : (isPrev ? "prev" : false);

            if (direction) {
                this.toggleBlocks(direction);
            }
        }
        
        if (e.keyCode === 17) {
            this.ctrlIsActive = true;
        }
    };

    onWheelListener = (e) => {
        if (!this.props.resolution.isMob && !this.ctrlIsActive) {
            const direction = e.deltaY < 0 ? "prev" : "next";
            this.toggleBlocks(direction);
        }
    };

    onSwipeStart = () => {
        this.swipeY = 0;
    };

    onSwipeMove = (e) => {
        this.swipeY = e.y;
    };

    onSwipeEnd = () => {
        const
            swipeRange = window.innerHeight / 6,
            minSwipe = swipeRange > 150 ? 150 : swipeRange;

        if (Math.abs(this.swipeY) >= minSwipe) {
            const direction =  this.swipeY > 0 ? 'prev' : 'next';
            this.toggleBlocks(direction);
        }
    };

    toggleBlocks = (direction) => {
        const { scroll } = this.props;

        if (this.readyToToggle && !scroll.prevent) {
            this._preventScreenToggle();

            let { count, initialScreen, screensId } = this.state,
                id = this.props.scroll.id,
                activeId = id ? id : initialScreen,
                currentIndex = screensId.indexOf(activeId),
                newPage = direction ? (direction === "next" ? currentIndex + 1 : currentIndex - 1) : -1,
                fixedVal = newPage < 0 ? 0 : (newPage > count ? count : newPage),
                newId = screensId[fixedVal];

            if (activeId !== newId) this.props.changeActiveBlock(newId);
        }
    };

    _preventScreenToggle = () => {
        this.readyToToggle = false;
        clearTimeout(this.readyToToggleTimer);

        this.readyToToggleTimer = setTimeout(() => {
            this.readyToToggle = true;
        }, styles.blocksFadeDuration * 2);
    };

    renderScrollsWrappers = () => {
        const
            { initialScreen, screensId } = this.state,
            { scroll, resolution, location } = this.props,
            isDifferentPathNames = location.pathname !== window.location.pathname;

        return this.props.children.map((item, key) => {
            const { id, data } = item.props,
                activeId = scroll.id ? scroll.id : initialScreen,
                showBlock = isDifferentPathNames ? false : (resolution.isMob ? (this.activeBlocks.indexOf(id) !== -1) : id === activeId);
            
            return (
                <ScrollWrap screensId={screensId} resolution={resolution} relatedRef={this.screensRefs[id]} data={data} id={id} key={key} show={showBlock}>
                    {item}
                </ScrollWrap>
            )
        });
    };

    switchContainer = () => {
        const
            { resolution } = this.props,
            { fade } = this.state,
            fadeMod = fade ? '_fade' : '';

        if (resolution.isMob) {
            return (
                <div ref={this.mobWrap} className={`${css_style} ${fadeMod}`}>
                    {this.renderScrollsWrappers()}
                </div>
            )
        } else {
            return (
                <Swipe
                    className={`${css_style} ${fadeMod}`}
                    onWheel={this.onWheelListener}
                    onSwipeStart={this.onSwipeStart}
                    onSwipeMove={this.onSwipeMove}
                    onSwipeEnd={this.onSwipeEnd}
                >
                    {this.renderScrollsWrappers()}
                </Swipe>
            )
        }
    };

    render() {
        return (
            this.switchContainer()
        );
    }
}

const mapStateToProps = store => {
    return {
        scroll: store.scroll
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeStep: step => dispatch(changeAIStep(step)),
        changeActiveBlock: id => dispatch(changeActiveBlock(id)),
        changeActiveLink: id => dispatch(changeActiveLink(id)),
        changeResolution: val => dispatch(changeResolution(val)),
        PreventScroll: val => dispatch(PreventScroll(val)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScrollContainer);


