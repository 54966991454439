import React from "react";
import { css } from "emotion";
import Title from "../Title";
import TextBlock from "../TextBlock";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import Parallax from "../../plugins/parallax/parallax";

const css_style = css({
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__content': {
        width: '100%',
        position: 'relative',
        marginRight: 'auto'
    },

    '&__col': {
        width: 'calc(50% - 10px)',
        transitionDelay: `${styles.blocksFadeDuration}ms`,

        '&_left': {
            transform: 'translateY(200px)',
            opacity: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        '&_right': {
            transform: 'translateY(-200px)',
            opacity: '0'
        }
    },

    '&__title-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '620px'
    },

    '&__text-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
    },

    '&__parallax-container': {
        width: 'calc(100% / 5 * 2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    '&__img-parallax-wrap': {
        display: 'block',
        width: '120px',
        maxWidth: '100%',
        height: '550px',
        overflow: 'hidden',
        position: 'relative'
    },

    '&__bg-wrap, &__mask-wrap': {
        ...styles.absolute('50%', 'auto', 'auto', '50%'),
        transform: 'translate(-50%, -50%)',
        display: 'block',
        width: '850%',
        height: '200%'
    },

    '&__bg, &__mask': {
        position: 'relative',
        display: 'block',
        width: '100%',
        height: '100%'

    },

    '&__parallax-block': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '100%'
    },

    '&__bg-wrap': {
        zIndex: 1
    },

    '&__mask-wrap': {
        zIndex: 2
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        maxWidth: '100%',
        margin: '0 auto'
    },

    '&._show': {

    },
    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        }
    },

    [styles.bp(1700)]: {
        '&__parallax-container': {
            width: 'calc(100% / 5 * 1.5)',

        },
        '&__img-parallax-wrap': {
            width: '100px',
            height: '400px'
        },


        '&__col': {
            '&_left': {
                width: 'calc(45% - 10px)'
            },
            '&_right': {
                width: 'calc(55% - 10px)'
            }
        },
    },
    [styles.bp(1300)]: {

    },


    [styles.bp(1000)]: {
        '&__table': {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },

        '&__img-parallax-wrap': {
            width: '44px',
            height: '200px'
        },

        '&__col': {
            width: '100%',
            transitionDelay: `0ms`,

            '&_left': {
                transform: 'translateY(100px)',
                maxWidth: '300px',
                margin: '30px auto 0'
            },
            '&_right': {
                transform: 'translateY(-100px)',
            }
        },

        '&._show &': {
            '&__col': {
                transitionDelay: `0ms`,
            }
        },
    },

    [styles.bp(700)]: {

    },
});



const css_style_advantages = css({
    width: 'calc(100% / 5 * 3)',

    '&__list': {
        display: 'block',
        position: 'relative',
        paddingRight: '20px'
    },

    '&__item': {
        display: 'block',
        position: 'relative',
        marginBottom: '95px',
        paddingLeft: '53px',
        ...fonts.p2,

        '&:last-child': {
            marginBottom: '0'
        },

        '&::before': {
            content: '""',
            display: 'block',
            ...styles.absolute('0.5em', 'auto', 'auto'),
            width: '28px',
            height: '1px',
            background: styles.colors.blue
        }
    },

    [styles.bp(1700)]: {
        width: 'calc(100% / 5 * 3.5 - 20px)',

        '&__item': {
            marginBottom: '70px',
            paddingLeft: '30px',

            '&::before': {
                width: '15px',
            }
        },

    },
    [styles.bp(1300)]: {

    },
    [styles.bp(1000)]: {
    },
    [styles.bp(700)]: {
        '&__item': {
            marginBottom: '40px',
        }
    },
});

class SalesModels extends React.Component {

    constructor(props) {
        super(props);

        this.bgContainer = React.createRef();
        this.parallax = null;
    }

    initParallax = () => {
        this.parallax = new Parallax(this.bgContainer.current, {
            relativeInput: false,
            invertX: false,
            invertY: false,
            scalarX: 15,
            scalarY: 10
        });
    };

    componentDidMount() {
        this.initParallax();
    }

    componentDidUpdate() {
        const { mod } = this.props;

        if ((mod === ' _show') && this.parallax) {
            this.parallax.enable();
        } else {
            this.parallax.disable();
        }
    }

    render() {
        const { mod, data } = this.props;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__col ${css_style}__col_left`}>
                                <div className={`${css_style}__parallax-container`}>
                                    <div className={`${css_style}__img-parallax-wrap`}>
                                        <div  className={`${css_style}__bg-wrap`}>
                                            <div ref={this.bgContainer} className={`${css_style}__parallax-block`}>
                                                {<img src={require(`../../images/svg/sales-models-bg.svg`)} className={`${css_style}__bg layer `} data-depth="1" alt='sales-models-bg'/>}
                                            </div>
                                        </div>

                                        <div className={`${css_style}__mask-wrap`}>
                                            {data.img && <img src={data.img} className={`${css_style}__mask`} alt='sales-models-mask'/>}
                                        </div>
                                    </div>
                                </div>

                                <div className={`${css_style_advantages}`}>
                                    <ul className={`${css_style_advantages}__list`}>
                                        {data.advantages &&
                                        data.advantages.map((item, key) => {
                                            return (
                                                <li key={key} className={`${css_style_advantages}__item`}>{require("html-react-parser")(item)}</li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right`}>
                                <div className={`${css_style}__content`}>
                                    <div className={`${css_style}__title-wrap`}>
                                        {data.title &&
                                            <Title data={data.title}/>
                                        }
                                    </div>

                                    <div className={`${css_style}__text-wrap`}>
                                        {data.text_block &&
                                            <TextBlock data={data.text_block}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SalesModels;
