import React from "react";
import { css } from "emotion";

import { ReactComponent as ProductsScreen } from "../../images/svg/animate/products_screen.svg";
import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";
import Slider from "react-slick";
import Button from '../../components/Button';
import Title from "../Title";
import TextBlock from "../TextBlock";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";

const css_style = css({
    display: 'block',
    position: 'relative',
    width: '100%',

    '&__title-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '500px'
    },

    '&__text-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '460px'
    },
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__col': {
        transitionDelay: `${styles.blocksFadeDuration}ms`,
        height: '100%',
        display: 'flex',
        alignItems: 'center',

        '&_left': {
            width: 'calc(100% / 10 * 4 - 10px)',
            transform: 'translateY(-200px)',
            opacity: '0',
            justifyContent: 'flex-start',
        },
        '&_right': {
            width: 'calc(100% / 10 * 6 - 10px)',
            transform: 'translateY(200px)',
            opacity: '0',
            justifyContent: 'center',

        }
    },


    'path:not([class*="-step1"])': {
        opacity: '0'
    },


    '&._show': {

    },
    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        }
    },

    [styles.bp(1700)]: {
        '&__col': {
            '&_right': {
                width: 'calc(100% / 10 * 5 - 10px)',
            }
        },
    },

    [styles.bp(1000)]: {
        '&__col': {
            transitionDelay: `0ms`,

            '&_left': {
                width: 'calc(100% / 10 * 5 - 10px)',
                transform: 'translateY(-100px)',
            },

            '&_right': {
                width: 'calc(100% / 10 * 4 - 10px)',
                transform: 'translateY(100px)',
            }
        },

        '&._show &': {
            '&__col': {
                transitionDelay: `0ms`,
            }
        },
    },

    [styles.bp(700)]: {
        '&__table': {
            flexWrap: 'wrap'
        },

        '&__col': {
            width: '100%',

            '&_right': {
                marginTop: '10px'
            }
        },
    }
});


const css_product_toggle = css({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '&__list': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        zIndex: '3'
    },

    '&__text': {
        marginTop: '10px',
        ...fonts.p5
    },

    '&__svg-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        zIndex: '2',

        'svg': {
            display: 'block',
            position: 'relative',
            maxWidth: '100%',
            maxHeight: '100%',
            height: 'auto'
        }
    },

    '&__label': {
        ...fonts.p7,
        ...styles.fixed('auto'),
        color: styles.colors.gray_light,
        marginBottom: '-0.3em',
        textAlign: 'center',
        opacity: '0.4',
        zIndex: '1'
    },

    '&__item': {
        width: 'calc(100% / 3 - 20px)',
        maxWidth: '240px',
        position: 'relative',
        paddingTop: '35px',
        opacity: '0.4',
        transition: styles.transitions.default,
        cursor: 'default',

        '&::before': {
            content: '""',
            ...styles.absolute('0','auto','auto','0'),
            width: '20px',
            height: '20px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${styles.colors.cyan} transparent`,
            borderWidth: '0 10px 20px 10px',
            boxSizing: 'border-box',
            opacity: '0',
            transition: styles.transitions.default

        }
    },

    '&__btn-wrap': {
        opacity: '0',
        transition: styles.transitions.default
    },

    '&__item._active': {
        opacity: '1',

        '&::before': {
            opacity: '1'
        }
    },

    '&__item._active &': {
        '&__btn-wrap': {
            opacity: '1'
        }
    },

    [styles.bp(1000)]: {
        alignItems: 'flex-start',
        maxWidth: '300px',
        margin: '0 auto',

        '&__label': {
            position: 'relative',
            marginTop: '20px'
        },

        '&__item': {
            textAlign: 'center',
            maxWidth: 'none',

            '&::before': {
                right: '0',
            }
        },

        '.slick-dots': {
            position: 'relative',
            marginTop: '20px',
            bottom: '0',

            'li': {
                margin: '0 3px',

                'button': {
                    '&::before': {
                        opacity: '1',
                        transition: styles.transitions.default
                    }
                },

                '&.slick-active': {
                    'button': {
                        '&::before': {
                            color: styles.colors.cyan
                        }
                    },
                }
            }
        }
    },

    [styles.bp(700)]: {
        '&__text': {
            marginTop: '15px',
        },
    }
});

class Products extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();

        this.sliderSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            speed: 500,
            dots: true,
            arrows: false,
        };
    }

    componentDidMount() {
        this.svg = this.svgContainer.current;
        this.objectsCount = this.svg.querySelectorAll('[class*="-step1"]').length;
        this.setMorph();
    }

    componentDidUpdate() {
       this.setMorph();
    }

    renderList = (list) => {
        const { activeTab } = this.state;

        return list.map((item, key) => {
            const
                { title, text, link } = item,
                mod = activeTab === key ? '_active' : '',
                titleData = {
                    text: title,
                    highlight: 'KIMI',
                    mod: 'p2u _reverse-highlight'
                };

            return(
                <div key={key} className={`${css_product_toggle}__item ${mod}`} onMouseEnter={this.onSelect.bind(this, key)}>
                    <Title data={titleData}/>

                    <p className={`${css_product_toggle}__text`}>
                        {require("html-react-parser")(text)}
                    </p>

                    <div className={`${css_product_toggle}__btn-wrap`}>
                        <Button link={link.href} text={link.text}/>
                    </div>
                </div>
            )
        });
    };

    setMorph = () => {
        const { activeTab } = this.state;

        for (var i = 1; i <= this.objectsCount; i++) {
            const
                morphFrom = this.svg.querySelector(`.js-object${i}-step1`),
                morphTo = this.svg.querySelector(`.js-object${i}-step${activeTab + 1}`),
                fill =  morphTo.getAttribute('fill');

            let tl = new TimelineMax({repeat: 0});

            if (morphFrom) {
                tl.to(morphFrom, 2, {
                    ease: Elastic.easeInOut.config(1, 0.75),
                    morphSVG: morphTo,
                    fill: fill
                }, 'now');
            }
        }
    };

    onSelect = (key) => {
        this.setState({
            activeTab: key
        })
    };

    switchTabsWrap = (list) => {
        const { resolution } = this.props;

        if (resolution.isMob) {
            return (
                <Slider afterChange={(oldIndex, newIndex) => {this.onSelect(oldIndex)}} className={`${css_product_toggle}__list`} {...this.sliderSettings}>
                    {this.renderList(list)}
                </Slider>
            )
        } else {
            return (
                <div className={`${css_product_toggle}__list`}>
                    {this.renderList(list)}
                </div>
            )
        }
    };

    render() {
        const { activeTab } = this.state;
        const { mod, data, resolution } = this.props;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__col ${css_style}__col_left`}>
                                <div className={`${css_style}__content`}>
                                    <div className={`${css_style}__title-wrap`}>
                                        {data.title &&
                                        <Title data={data.title}/>
                                        }
                                    </div>

                                    <div className={`${css_style}__text-wrap`}>
                                        {data.text_block &&
                                            <TextBlock data={data.text_block}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right`}>

                                <div className={`${css_product_toggle}`}>
                                    <div ref={this.svgContainer} className={`${css_product_toggle}__svg-wrap`}>
                                        <ProductsScreen/>
                                    </div>

                                    {data.list && this.switchTabsWrap(data.list)}

                                    {!resolution.isMob &&
                                        <div className={`${css_product_toggle}__label`}>
                                            {data.list && require("html-react-parser")(data.list[activeTab].title)}
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Products;
