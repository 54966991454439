import React from "react";
import { css } from "emotion";
import { NavLink } from "react-router-dom";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";

const css_style = css({
    display: 'block',
    position: 'relative',
    maxWidth: '400px',

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '185px',

        'svg': {
            display: 'block',
            position: 'relative',
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            marginRight: 'auto',
            left: '-10px'
        }
    },


    '&__title': {
        ...fonts.h3,
        display: 'block',
        color: styles.colors.gray_dark,
        transition: styles.transitions.default,
        marginTop: '45px',

        '&:last-of-type': {
            marginBottom: '0'
        }
    },

    '&._small &': {
        '&__title': {
            marginTop: '20px',
            ...fonts.p2u
        },

        '&__img-wrap': {
            display: 'block',
            position: 'relative',
            height: '100px',
            width: '100%',
        }
    },

    '&:hover &': {
        '&__title': {
            color: styles.colors.cyan
        },
    },

    '[class*="-step2"]': {
        opacity: '0'
    },

    [styles.bp(1700)]: {
        '&__title': {
            marginTop: '30px',
        },

        '&__img-wrap': {
            height: '125px',
        },

        '&._small &': {
            '&__title': {
                marginTop: '15px',
            },

            '&__img-wrap': {
                height: '75px'
            }
        },
    },

    [styles.bp(1300)]: {
        '&__title': {
            marginTop: '20px',
        },
        '&__img-wrap': {
            height: '110px',
        },

        '&._small &': {
            '&__title': {
                marginTop: '10px',
            },

            '&__img-wrap': {
                height: '70px'
            }
        },
    },

    [styles.bp(1000)]: {
        '&__title': {
            marginTop: '15px',
        },
        '&__img-wrap': {
            height: '100px',
        },

        '&._small &': {
            '&__title': {
                marginTop: '0',
                width: 'calc(100% - 65px)',
                maxWidth: '140px',
                paddingLeft: '10px'
            },

            '&__img-wrap': {
                width: '65px',
                height: '65px',

                'svg': {
                    left: '0'
                }
            },

            '&__content': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }
        },
    },

    [styles.bp(700)]: {
        maxWidth: 'none',

        '&__title': {
            marginTop: '10px',
        },
        '&__img-wrap': {
            height: '70px',

            'svg': {
                left: '0'
            }
        },

        '&._small &': {
            '&__img-wrap': {
                width: '55px',
                height: '45px',
            },

            '&__title': {
                maxWidth: 'none',
            },
        },
    },

    [styles.bp(500)]: {
        '&:not(._small)  &': {
            '&__img-wrap': {
                'svg': {
                    margin: 'auto'
                }
            },
            '&__title': {
                textAlign: 'center',
                maxWidth: '150px',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        }
    }
});

class SvgMorphLink extends React.Component {
    constructor(props) {
        super(props);

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();
    }

    componentDidMount() {
        this.svg = this.svgContainer.current;
        this.objectsCount = this.svg.querySelectorAll('[class*="-step1"]').length;
        this.tl = new TimelineMax({repeat: -1, yoyo: true, paused: true});
        this.initMorph();
    }

    initMorph = () => {
        for (var i = 1; i <= this.objectsCount; i++) {
            const morphFrom = this.svg.querySelector(`.js-object${i}-step1`),
                morphTo = this.svg.querySelector(`.js-object${i}-step2`),
                fill = (morphTo && morphTo.length) ? morphTo.getAttribute('fill') : false;

            if (morphFrom) {
                let morphObj = {
                    ease: Elastic.easeInOut.config(1, 0.75)
                };

                morphObj.morphSVG = morphTo ? morphTo : this.svg.querySelector(`.js-object${i}-step1`);
                morphObj.fill = fill ? fill : morphObj.morphSVG.getAttribute('fill');

                this.tl.to(morphFrom, 2, morphObj, 'now');
            }
        }
    };

    handleHoverOn = () => {
        this.tl.play();
    };

    handleHoverOff = () => {
        this.tl.pause().reverse(1);
    };
   
    render() {
        const { title='', link='/', mod='' } = this.props,
            isSmall = mod === '_small',
            titleMod  = isSmall ? `p2u` : '',
            TitleTag  = isSmall ? 'span' : 'h3';

        return (
            <NavLink to={link} className={`${css_style} ${mod}`} onMouseEnter={this.handleHoverOn} onMouseLeave={this.handleHoverOff}>
                <div ref={this.svgContainer} className={`${css_style}__content`}>
                    <div className={`${css_style}__img-wrap`}>
                        {this.props.children}
                    </div>

                    <TitleTag className={`${css_style}__title ${titleMod}`}>{require("html-react-parser")(title)}</TitleTag>
                </div>
            </NavLink>
        )
    }
}

export default SvgMorphLink;
