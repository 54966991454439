import React from "react";
import { css } from "emotion";
import { NavLink } from "react-router-dom";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import arrowDropdown from '../../images/svg/arrow-dropdown.svg';
import arrowDropdownWhite from '../../images/svg/arrow-dropdown_white.svg';
import Collapse from "@kunukn/react-collapse";

const css_style = css({
    marginBottom: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textDecoration: 'none',

    '&:last-of-type': {
        marginBottom: '0'
    },

    '.collapse-css-transition': {
        transition: 'height 500ms cubic-bezier(0.4, 0, 0.2, 1)'
    },

    '&__link-wrap': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    '&__link': {
        color: styles.colors.gray_dark,
        cursor: 'pointer',
        transition: styles.transitions.default,
        ...fonts.b2m,

        '&:hover, &._active': {
            color: styles.colors.cyan
        }
    },

    '&__arrow': {
        padding: '5px',
        marginLeft: '5px',
        cursor: 'pointer',

        '&::before': {
            content: '""',
            display: 'block',
            width: '8px',
            height: '6px',
            background: `url(${arrowDropdown}) center center no-repeat`,
            backgroundSize: 'contain',
            transformOrigin: '50%, 50%',
            transition: styles.transitions.default
        },

        '&._active': {
            '&::before': {
                transform: 'scaleY(-1)'
            },
        }
    },

    '&__inner': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '30px 0 12px',
        overflow: 'hidden'
    },

    '&__sub-link': {
        marginBottom: '17px',
        color: styles.colors.blue,
        transition: styles.transitions.default,
        paddingLeft: '32px',
        display: 'block',
        position: 'relative',
        transform: 'translateX(-32px)',
        ...fonts.b3s,

        '&::before': {
            content: '""',
            position: 'absolute',
            right: 'auto',
            bottom: 'auto',
            left: '0',
            top: '0.5em',
            display: 'block',
            width: '20px',
            height: '1px',
            transition: styles.transitions.default,
            background: styles.colors.blue
        },

        '&:last-child': {
            marginBottom: '0'
        },

        '&:hover, &._active': {
            color: styles.colors.cyan,
            transform: 'translateX(0)',

            '&::before': {
                background: styles.colors.cyan
            }
        }
    },

    "&._light &": {
        '&__sub-link': {
            color: styles.colors.gray_dark,

            '&::before': {
                background: styles.colors.gray_dark
            },

            '&:hover': {
                color: styles.colors.white,

                '&::before': {
                    background: styles.colors.white
                }
            }
        },

        '&__link': {
            color: styles.colors.gray_dark,

            '&:hover, &._active': {
                color: styles.colors.white
            }
        },

        '&__arrow': {
            '&::before': {
                background: `url(${arrowDropdownWhite}) center center no-repeat`
            }
        },
    },

    [styles.bp(1700)]: {
        marginBottom: '15px',

        '&__inner': {
            padding: '20px 0 10px',
        },

        '&__sub-link': {
            marginBottom: '10px',
        }
    },
});

class NavItem extends React.Component {
    constructor(props) {
        super(props);

        // const { hasMatch } = this.props;

        this.state = {
            activeTab: false,
            showInner: true
        };

        this.statePriority = true;
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasMatch !== prevProps.hasMatch) {
            this.statePriority = false;

            this.setState({
                showInner: false
            });
        }
    }

    onDropdownClick = () => {
        this.statePriority = true;

        this.setState({
            showInner: !this.state.showInner
        });
    };

    scroll = (id) => {
        this.props.scrollTo(id)
    };

    renderNavItem = (itemObj, active) => {
        const
            { exact, link, text, id } = itemObj,
            { showInner } = this.state,
            { theme, hasMatch } = this.props,
            showArrow = this.statePriority ? showInner : hasMatch;

        return (
            <div className={`${css_style} ${theme}`}>
                <div className={`${css_style}__link-wrap`}>
                    {
                        link
                            ? <NavLink onClick={this.onClick} to={link} exact={exact} className={`${css_style}__link ${active ? '_active' : ''}`} activeClassName="_active">{require("html-react-parser")(text)}</NavLink>
                            : <span className={`${css_style}__link ${active ? '_active' : ''}`} onClick={() => {this.scroll(id); this.onClick()}}>{require("html-react-parser")(text)}</span>
                    }

                    {itemObj.sub && (
                        <div className={`${css_style}__arrow ${showArrow ? '_active' : ''}`} onClick={this.onDropdownClick}/>
                    )}
                </div>

                {itemObj.sub && (
                    this.renderInner(itemObj)
                )}
            </div>
        );
    };

    onClick = () => {
        const { clickHandler } = this.props;

        if (clickHandler) clickHandler();
    };

    renderInner = (itemObj) => {
        const
            { showInner } = this.state,
            { hasMatch } = this.props,
            show = this.statePriority ? showInner : hasMatch;

        return (
            <Collapse isOpen={show}>
                <div className={`${css_style}__inner`}>
                    {itemObj.sub.map((item, key) => {
                        const { exact, link, text } = item;

                        return <NavLink onClick={this.onClick} key={key} to={link} exact={exact} className={`${css_style}__sub-link`} activeClassName="_active">{require("html-react-parser")(text)}</NavLink>
                    })}
                </div>
            </Collapse>
        )
    };

    render() {
        let { itemObj, active } = this.props;

        return (
            this.renderNavItem(itemObj, active)
        )
    }
}

export default NavItem;
