import React, { Component } from "react";
import { css } from "emotion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import validate from "../../utils/validate-yup";
import collect_schema from "../../utils/collect-schema";
import Button from '../../components/Button';
import styles from "../../constants/styles";

import {
    InputStyle
} from "../fields";

const form_style = css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    '&__item': {
        width: '100%',
        marginBottom: '20px',

        '&:last-of-type': {
            marginBottom: '0',
        },

        '&:nth-of-type(1), &:nth-of-type(2)': {
            width: 'calc(50% - 10px)'
        }
    },

    [styles.bp(1700)]: {
        '&__item': {
            marginBottom: '15px',
        },

    },

    [styles.bp(1000)]: {
        '&__item': {
            '&:nth-of-type(1), &:nth-of-type(2)': {
                width: '100%'
            }
        },
    }
});

function initValues(fields) {
    var obj = {};

    for (var i = 0; i < fields.length; i++) {
        obj[fields[i].name] = fields[i].value || '';
    }

    return obj;
}

class FormTemplate extends Component {
    render() {
        let { handleSubmit } = this.props,
            fields = this.props.data.fields,
            elements = function(fields, initialValues) {
                let arr = [];

                for (let i = 0; i < fields.length; i++) {
                    let item = fields[i];

                    arr.push(
                        <div className={`${form_style}__item`} key={i}>
                            <InputStyle data={item}/>
                        </div>
                    )
                }

                return arr;
            };

        let add_props = {};

        if (this.props.id) {
            add_props.id = this.props.id;
        }

        return (
            <Form {...add_props} className={`${form_style}`} onSubmit={handleSubmit}>
                {elements(fields, this.props.initialValues)}
                <Button text={this.props.buttonText}/>
            </Form>
        );
    }
}


class FormDefault extends React.Component {
    render() {
        const {buttonText} = this.props;

        let _this = this,
            fields = _this.props.data.fields,
            schema_props = _this.props.schema,

            getYup = function(values) {
                let schema = {};
                return Yup.object(collect_schema(schema, fields));
            };

        return (
            <Formik
                enableReinitialize={true}
                initialValues={
                    this.props.initValues
                        ? {...initValues(fields), ...this.props.initValues}
                        : initValues(fields)
                }
                validate={schema_props ? validate(schema_props) : validate(getYup)}
                onSubmit={(values, actions) => {
                    this.props.handleSubmit(values, actions);
                }}
                render={props => (
                    <FormTemplate
                        mainActions={this.props.mainActions}
                        data={this.props.data}
                        mod={this.props.mod}
                        id={this.props.id ? this.props.id : ""}
                        buttonText={buttonText}
                        {...props}
                    />
                )}
            />
        );
    }
}

export default FormDefault;
