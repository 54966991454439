import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import Slider from "react-slick";
import CustomSelect from '../../components/CustomSelect';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowIconWhite from '../../images/svg/arrow-slider_white.svg';

import TextBlock from "../TextBlock";

const css_style = css({
    display: 'block',
    position: 'relative',
    width: '100%',

    [styles.bp(1300)]: {
        maxWidth: '500px',
        margin: '0 auto'
    }
});

const css_slider = css({
    display: 'block',
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    overflow: 'hidden',

    '&__select-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        marginBottom: '20px',
    },

    '&__item': {
        display: 'flex !important',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        width: '100%',
        height: '100%'
    },

    '&__description': {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        marginTop: '30px',
        width: 'calc(100% - 35px)'
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        width:'100%'
    },

    '.slick-track': {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start'
    },

    '.slick-arrow': {
        width: '35px',
        height: '35px',
        top: 'auto',
        right: '0',
        left: 'auto',
        transform: 'none',
        opacity: '1',
        zIndex: '5',
        background: styles.colors.cyan,

        '&::before': {
            content: '""',
            position: 'relative',
            display: 'block',
            width: '100%',
            height: '100%',
            margin: 'auto',
            background: `url(${arrowIconWhite}) center center no-repeat`,
            backgroundSize: '50% 50%',
            transformOrigin: '50%, 50%',
            opacity: '1'
        },

        '&:hover, &:focus': {
            background: 'rgba(0,0,0,.2)',

            '&::before': {
                opacity: '1'
            }
        },

    },

    '.slick-next': {
        bottom: '35px'
    },

    '.slick-prev': {
        bottom: '0',

        '&::before': {
            transform: 'scaleX(-1)'
        }
    },

    '.slick-slide': {
        height: 'auto',

        '& > *': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%'
        }
    },

    '.slick-active': {
        zIndex: '3'
    },

    '.slick-dots': {
        ...styles.slickDots
    },

    [styles.bp(1300)]: {
        '&__img-wrap': {
            marginTop: '15px',
        },
    },

    [styles.bp(1000)]: {
        '&__item': {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-end'
        },
        '&__description': {
            height: 'auto',
            marginTop: '20px',
        },

        '&__img-wrap': {
            marginTop: '0',
            width: '100%'
        },
    }
});

const css_nav = css({
    display: 'block',
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    overflow: 'hidden',

    '&__item': {
        position: 'relative',
        outline: 'none',
        padding: '36px 10px 0',
        cursor: 'pointer',
        transition: styles.transitions.default,
        ...fonts.p6,
        whiteSpace: 'nowrap',

        '&::before': {
            content: '""',
            ...styles.absolute('0','auto','auto', '10px'),
            width: '20px',
            height: '20px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${styles.colors.cyan} transparent`,
            borderWidth: '0 10px 20px 10px',
            boxSizing: 'border-box',
            opacity: '0',
            transform: 'translateY(10px)',
            transition: styles.transitions.default
        },

        '&:hover': {
            color: styles.colors.cyan
        }
    },


    '.slick-list': {
        margin: '0 -10px',

        '&::after': {
            content: '""',
            height: '100%',
            width: '20px',
            ...styles.absolute('0', '10px', '0', 'auto'),
            background: 'linear-gradient(270deg, rgba(255,255,255,1), rgba(255,255,255,0))',
            zIndex: '5',
            pointerEvents: 'none'
        },
    },

    '.slick-current &__item': {
        color: styles.colors.cyan,

        '&::before': {
            opacity: '1',
            transform: 'translateY(0)',

        }
    },

    [styles.bp(1300)]: {
        marginTop: '15px',

        '&__item': {
            paddingTop: '20px',

            '&::before': {
                width: '10px',
                height: '10px',
                borderWidth: '0 5px 10px 5px',
                transform: 'translateY(5px)',
            },
        },

    }
});


class TechnologySlider extends React.Component {
    constructor(props) {
        super(props);

        this.defaultMainSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            fade: true,
            speed: 500,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: styles.breakpoints[1000],
                    settings: {
                        arrows: false,
                        dots: true,
                    }
                },
            ]
        };

        this.defaultNavSettings = {
            variableWidth: true,
            infinite: true,
            speed: 500,
            dots: false,
            arrows: false,
        };

        this.state = {
            nav1: null,
            nav2: null
        };

        this.select = React.createRef();
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    onNavClick = (key) => {
        const { nav1 } = this.state;

        nav1.slickGoTo(key)
    };

    afterChange = (key) => {
        if (this.select.current) this.select.current.onOptionClick(key);
    };

    render() {
        const
            { data, resolution } = this.props,
            { slides, nav, customMainSettings={}, customNavSettings={} } = data,
            settingsMain = {...this.defaultMainSettings, ...customMainSettings},
            settingsNav = {...this.defaultNavSettings, ...customNavSettings};

        return (
            <div className={`${css_style}`}>
                <div className={`${css_slider}`}>
                    {resolution.isMob && nav &&
                        <div className={`${css_slider}__select-wrap`}>
                            <CustomSelect ref={this.select} list={nav} onSelect={this.onNavClick} titleSettings={{highlight: 'KIMI', mod: 'p6 _highlight-all'}}/>
                        </div>
                    }

                    {slides && <Slider beforeChange={(oldIndex, newIndex) => {this.afterChange(newIndex)}} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className={`${css_slider}__slider`} {...settingsMain}>
                        {
                            slides.map((item, key) => {
                                return (
                                    <div key={key} className={`${css_slider}__item`}>
                                        <div className={`${css_slider}__description`}>
                                            {item.text_block &&
                                            <TextBlock data={item.text_block}/>
                                            }
                                        </div>
                                        <div className={`${css_slider}__img-wrap`}>
                                            {item.img &&
                                            <img src={item.img} className={`${css_slider}__img`} alt={item.img}/>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>}
                </div>

                {!resolution.isMob &&
                <div className={`${css_nav}`}>
                    <Slider asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}
                            className={`${css_nav}__slider`} {...settingsNav}>
                        {nav &&
                        nav.map((item, key) => {
                            return (
                                <div key={key} className={`${css_nav}__item`} onClick={this.onNavClick.bind(this, key)}>
                                    {require('html-react-parser')(item)}
                                </div>
                            )
                        })
                        }
                    </Slider>
                </div>
                }
            </div>
        )
    }
}

export default TechnologySlider;
