import React, {Component} from "react";
import {css, keyframes} from "emotion";
import {Field} from "formik";
import styles from "../../../constants/styles";
import fonts from "../../../constants/fonts";
import checkSvg from '../../../images/svg/check.svg';

const autofill = keyframes`
  100% {
        background: transparent;
        color: inherit;
    }
`;

const input_style = css({
    position: "relative",
    width: "100%",

    "&__input": {
        display: "block",
        backgroundColor: "transparent",
        height: '52px',
        width: "100%",
        padding: "4px 20px 20px 0",
        color: styles.colors.blue,
        borderBottom: `2px solid ${styles.colors.blue}`,
        boxSizing: 'border-box',
        fontFamily: 'montserrat',
        ...fonts.p5,

        '&.textarea': {
            resize: 'none',
            height: '98px',
        },

        "::-webkit-input-placeholder": {
            ...fonts.p5,
            color: styles.colors.blue,
        },
        ":-ms-input-placeholder": {
            ...fonts.p5,
            color: styles.colors.blue,
        },
        "::-ms-input-placeholder": {
            ...fonts.p5,
            color: styles.colors.blue,
        },
        "::placeholder": {
            ...fonts.p5,
            color: styles.colors.blue,
        },

        "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
            animation: `${autofill} 0s forwards`
        },

        '&._error': {
            borderBottom: `2px solid ${styles.colors.orange_light}`,
        },

        '&._valid': {
            borderBottom: `2px solid ${styles.colors.cyan}`,
        }
    },

    '&__label-wrap': {
        marginTop: '10px'
    },

    "&__input-wrap": {
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '14px',
            height: '12px',
            left: 'auto',
            right: '0',
            top: '12px',
            bottom: 'auto',
            background: `url(${checkSvg}) center center no-repeat`,
            backgroundSize: 'contain',
            transition: styles.transitions.default,
            opacity: '0'
        }
    },
    "&__input-wrap._valid": {
        '&::before': {
            opacity: '1'
        }
    },

    "&__input-wrap._error + &__label": {
        color: styles.colors.orange_light
    },

    "&__label": {
        display: "block",
        position: 'relative',
        marginTop: '10px',
        color: styles.colors.gray_dark,
        fontFamily: 'montserrat',
        ...fonts.lf
    },

    [styles.bp(1700)]: {
        "&__input-wrap": {
            '&::before': {
                top: '6px',
            }
        },

        '&__input': {
            height: '30px',
            padding: '4px 20px 4px 0',

            '&.textarea': {
                height: '55px',
            },
        },

        "&__label": {
            marginTop: '6px',
        },
    }
});

class InputStyle extends Component {
    renderItem = (field, form) => {
        const {label, type, placeholder} = this.props.data,
            isTouchEnd = form.touched[field.name],
            isErrorExist = isTouchEnd && form.errors[field.name],
            isValid = isTouchEnd && field.value && !isErrorExist,
            inputMod = isErrorExist ? '_error' : (isValid ? '_valid' : ''),
            inputType = type ? type : "text";

        return (
            <React.Fragment>
                <div className={`${input_style}__input-wrap ${inputMod}`}>
                    {inputType === 'textarea'
                        ?
                        <textarea
                            {...field}
                            className={`${input_style}__input ${inputMod} ${type}`}
                            placeholder={placeholder}
                        />
                        :
                        <input
                            {...field}
                            type={inputType}
                            className={`${input_style}__input ${inputMod} ${type}`}
                            placeholder={placeholder}
                        />
                    }
                </div>

                <span className={`${input_style}__label`}>
                    {require("html-react-parser")(isErrorExist ? form.errors[field.name] : label)}
                </span>
            </React.Fragment>
        )
    };

    render() {
        const {counter, name} = this.props.data,
            input_counter = counter ? counter : "";

        return (
            <div className={input_style}>
                <Field name={`${name}${input_counter}`}>
                    {({field, form}) => (
                        this.renderItem(field, form)
                    )}
                </Field>
            </div>
        );
    }
}

export default InputStyle;
