import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import TextBlock from '../../components/TextBlock';
import Button from '../../components/Button';
import RotatedText from '../../components/RotatedText';
import InnerScrollWrap from "../../containers/InnerScrollWrap";


const css_style = css({
    '&__content': {
        display: 'block',
        position: 'relative',
        padding: '100px 0',
        width: 'calc(100% / 10 * 7)',
        margin: '0 auto 0 calc(100% / 10)'
    },

    '.container': {
        height: 'auto'
    },

    '&  &__aside-text-wrap': {
        ...styles.absolute('0', 'auto'),
        pointerEvents: 'none',
        zIndex: '1'
    },

    '&__title': {
        display: 'block',
        position: 'relative',
        ...fonts.h4,
        marginBottom: '50px',
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        marginBottom: '40px',
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: 'auto',
    },

    [styles.bpb(700,1000)]: {
        '&::before': {
            content: '""',
            ...styles.absolute('0','0','auto'),
            height: '100vh',
            width: 'calc(100% - 20px)',
            zIndex: '0',
            background: `url(${require('../../images/svg/mob/article-bg_mob.svg')}) center 90px no-repeat`,
            backgroundSize: 'contain',
            pointerEvents: 'none',
            transform: 'scale(0.7)',
            opacity: '0',
        },

        '&._show': {
            '&::before': {
                transition: styles.transitions.type7,
                transform: 'scale(1)',
                opacity: '1',
            },
        }
    },

    [styles.bp(1700)]: {
        '&__content': {
            padding: '70px 0',
        },

        '&__title': {
            marginBottom: '40px',
        },

        '&__img-wrap': {
            marginBottom: '30px',
        },
    },

    [styles.bp(1300)]: {
        '&__content': {
            padding: '30px 0',
        },

        '&__title': {
            marginBottom: '35px',
        },

        '&__img-wrap': {
            marginBottom: '25px',
        },
    },

    [styles.bp(1000)]: {
        '&__content': {
            padding: '20px 0',
            width: '100%',
            margin: '0'
        },

        '&__title': {
            marginBottom: '35px',
        },
    },


    [styles.bp(700)]: {
        '&__content': {
            padding: '0',
        },

        '&__title': {
            marginBottom: '30px',
        },
    }
});

class Article extends React.Component {
    render() {
        const { data, mod, resolution, screensId, id } = this.props;
        const isShowed = mod === ' _show';

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className={`${css_style}__aside-text-wrap container`}>
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}
                </div>

                <InnerScrollWrap parentId={id} screensId={screensId} relatedRef={ref => (this.scrollWrap = ref)}  resolution={resolution} isShowed={isShowed}>
                    <div className="container">
                        <div className="inner-container">
                            <div className={`${css_style}__content`}>
                                {data.title &&
                                    <h3 className={`${css_style}__title`}>{require("html-react-parser")(data.title)}</h3>
                                }

                                {data.img && data.img.desk &&
                                    <picture className={`${css_style}__img-wrap`} >
                                        {data.img.mob && <source media="(max-width: 600px)" srcSet={data.img.mob}/>}
                                        <img src={data.img.desk} className={`${css_style}__img`} alt="service"/>
                                    </picture>
                                }

                                {data.text_block &&
                                    <TextBlock data={data.text_block}/>
                                }

                                {data.button_more &&
                                    <Button link={data.button_more.link} text={data.button_more.text}/>
                                }
                            </div>

                        </div>
                    </div>
                </InnerScrollWrap>
            </section>
        )
    }
}

export default Article;
