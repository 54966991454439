import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";

const css_style = css({
    ...styles.fixed(),
    width: '100%',
    height: '100%',
    zIndex: '0',
    pointerEvents: 'none',

    '&__top, &__bottom': {
        width: '200%',
        height: '0',
        transform: 'translateX(-50%)',

        '&::before': {
            content: '""',
            width: '100%',
            height: '100vh',
            borderRadius: '50%',
            background: styles.colors.cyan,
            transition: 'all .2s linear'
        }
    },

    '&__top': {
        ...styles.absolute('0','0','auto','50%'),

        '&::before': {
            ...styles.absolute('auto'),
        }
    },

    '&__bottom': {
        ...styles.absolute('auto','0','0','50%'),

        '&::before': {
            ...styles.absolute('0','0','auto'),
        }
    }
});

class ScrollEdge extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            topEdge: 0,
            bottomEdge: 0,
        };

        this.mlt = 10;
        this.readyToCheck = true;
        this.topEdgeResetInterval = null;
        this.bottomEdgeResetInterval = null;

        this.resetTopTimer = null;
        this.resetBottomTimer = null;
    }

    onEdge = (e) => {
        if (this.readyToCheck) {
            this.readyToCheck = false;

            this.checkEdge(e);

            setTimeout(() => {
                this.readyToCheck = true;
            }, 75)
        }
    };

    checkEdge = (direction) => {
        const { topEdge, bottomEdge } = this.state;
        const { parentRef } = this.props;
        const parent = parentRef.current;
        const isTopEdge = parent.scrollTop <= (parent.scrollHeight * 0.1);
        const isBottomEdge = parent.offsetHeight + parent.scrollTop >= (parent.scrollHeight * 0.9);

        if (direction === 'up' && isTopEdge) {
            this.clearTop();

            const newVal = topEdge + this.mlt;
            if (!this.topEdgeResetInterval) {
                clearTimeout(this.resetTopTimer);

                this.resetTopTimer = setTimeout(() => {
                    this.resetTop();
                }, 500);
            }

            if (newVal >= 100) {
                this.switchBlock(direction);
            } else {
                this.setState({
                    topEdge: newVal,
                    bottomEdge: 0,
                });
            }
        }

        else if (direction === 'down' && isBottomEdge) {
            this.clearBottom();

            const newVal = bottomEdge + this.mlt;
            if (!this.bottomEdgeResetInterval) {
                clearTimeout(this.resetBottomTimer);

                this.resetBottomTimer = setTimeout(() => {
                    this.resetBottom();
                }, 200);
            }

            if (newVal >= 100) {
                this.switchBlock(direction);
            } else {
                this.setState({
                    topEdge: 0,
                    bottomEdge: newVal,
                })
            }
        } else {
            if (topEdge || bottomEdge) {
                this.clearEdge();
            }
        }
    };

    switchBlock = (direction) => {
        const { parentId, screensId, changeBLock } = this.props;
        const currentIndex = screensId.indexOf(parentId);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        this.clearEdge();
        changeBLock(screensId[newIndex]);
    };

    clearTop = () => {
        clearInterval(this.topEdgeResetInterval);
        this.topEdgeResetInterval = null;
    };

    clearBottom = () => {
        clearInterval(this.bottomEdgeResetInterval);
        this.bottomEdgeResetInterval = null;
    };

    clearEdge = () => {
        this.clearTop();
        this.clearBottom();

        this.setState({
            topEdge: 0,
            bottomEdge: 0,
        });
    };

    resetTop = () => {
        this.topEdgeResetInterval = setInterval(() => {
            const { topEdge } = this.state;

            if (topEdge  > 0) {
                this.setState({
                    topEdge: topEdge - 20
                });
            } else {

                this.clearTop();
            }
        }, 200);
    };

    resetBottom = () => {
        this.bottomEdgeResetInterval = setInterval(() => {
            const { bottomEdge } = this.state;

            if (bottomEdge > 0) {
                this.setState({
                    bottomEdge: bottomEdge - 20
                });
            } else {
                this.clearBottom();
            }
        }, 200);
    };

    render() {
        const { topEdge, bottomEdge } = this.state;

        const edge_top = css({
            '&::before': {
                transform: `translateY(${topEdge}px)`,
                opacity: `${topEdge / 100}`
            }
        });

        const edge_bottom = css({
            '&::before': {
                transform: `translateY(-${bottomEdge}px)`,
                opacity: `${bottomEdge / 100}`
            }
        });

        return (
            <div className={`${css_style}`}>
                <div className={`${css_style}__top ${edge_top}`} />
                <div className={`${css_style}__bottom ${edge_bottom}`} />
            </div>
        )
    }
}

export default ScrollEdge;
