import styles from "../constants/styles";

let fonts = {
    h1: {
        fontWeight: '700',
        fontSize: '96px',
        lineHeight: '117px',

        [styles.bp(1700)]: {
            fontSize: '64px',
            lineHeight: '78px',
        },
        [styles.bp(1300)]: {
            fontSize: '50px',
            lineHeight: '61px',
        },
        [styles.bp(1000)]: {
            fontSize: '42px',
            lineHeight: '51px',
        },
        [styles.bp(700)]: {
            fontSize: '31px',
            lineHeight: '38px',
        },
    },
    h1a: {
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '59px',
        textTransform: 'none',

        [styles.bp(1700)]: {
            fontSize: '38px',
            lineHeight: '46px',
        },
        [styles.bp(1300)]: {
            fontSize: '30px',
            lineHeight: '37px',
        },
        [styles.bp(1000)]: {
            fontSize: '24px',
            lineHeight: '29px',
        },
        [styles.bp(700)]: {
            fontSize: '21px',
            lineHeight: '26px',
        },
    },
    h1n: {
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '59px',

        [styles.bp(1700)]: {
            fontSize: '38px',
            lineHeight: '46px',
        },
        [styles.bp(1300)]: {
            fontSize: '30px',
            lineHeight: '37px',
        },
        [styles.bp(1000)]: {
            fontSize: '24px',
            lineHeight: '29px',
        },
        [styles.bp(700)]: {
            fontSize: '21px',
            lineHeight: '26px',
        },
    },

    h2: {
        fontWeight: '700',
        fontSize: '80px',
        lineHeight: '98px',
        marginBottom: '40px',

        [styles.bp(1700)]: {
            fontSize: '48px',
            lineHeight: '59px',
            marginBottom: '25px',
        },
        [styles.bp(1300)]: {
            fontSize: '38px',
            lineHeight: '46px',
            marginBottom: '15px',
        },
        [styles.bp(1000)]: {
            fontSize: '30px',
            lineHeight: '37px',
        },
        [styles.bp(700)]: {
            fontSize: '25px',
            lineHeight: '30px',
        },
    },

    h3: {
        fontWeight: '700',
        fontSize: '44px',
        lineHeight: '54px',
        marginBottom: '30px',

        [styles.bp(1700)]: {
            fontSize: '30px',
            lineHeight: '37px',
            marginBottom: '20px',
        },
        [styles.bp(1300)]: {
            fontSize: '26px',
            lineHeight: '32px',
            marginBottom: '15px',
        },
        [styles.bp(1000)]: {
            fontSize: '22px',
            lineHeight: '27px',
        },
        [styles.bp(700)]: {
            fontSize: '21px',
            lineHeight: '26px',
        },
    },

    h4: {
        fontWeight: '700',
        fontSize: '28px',
        lineHeight: '34px',
        marginBottom: '20px',

        '&:last-child': {
            marginBottom: '0'
        },

        [styles.bp(1700)]: {
            fontSize: '20px',
            lineHeight: '24px',
            marginBottom: '15px',
        },
        [styles.bp(1300)]: {
            fontSize: '18px',
            lineHeight: '22px',
            marginBottom: '10px',
        },
        [styles.bp(700)]: {
            fontSize: '19px',
            lineHeight: '23px',
        },
    },

    p0: {
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '88.4%',

        [styles.bp(1700)]: {
            fontSize: '30px',
            lineHeight: '84.4%',
        },
        [styles.bp(1300)]: {
            fontSize: '26px',
            lineHeight: '32px',
        },
        [styles.bp(1000)]: {
            fontSize: '26px',
        },
        [styles.bp(700)]: {
            fontSize: '22px',
        },
    },

    p1: {
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '59px',

        [styles.bp(1700)]: {
            fontSize: '30px',
            lineHeight: '37px',
        },
        [styles.bp(1300)]: {
            fontSize: '26px',
            lineHeight: '32px',
        },
        [styles.bp(1000)]: {
            fontSize: '22px',
            lineHeight: '27px',
        },
        [styles.bp(700)]: {
            fontSize: '21px',
            lineHeight: '26px',
        }
    },

    p2: {
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '29px',

        [styles.bp(1700)]: {
            fontSize: '18px',
            lineHeight: '22px',
        },
        [styles.bp(1300)]: {
            fontSize: '16px',
            lineHeight: '20px',
        },
        [styles.bp(700)]: {
            fontSize: '17px',
            lineHeight: '21px',
        },
    },

    p3: {
        display: 'block',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '22px',
        marginBottom: '20px',

        [styles.bp(1700)]: {
            fontSize: '14px',
            lineHeight: '17px',
            marginBottom: '15px',
        },
        [styles.bp(700)]: {
            fontSize: '15px',
            lineHeight: '18px',
            marginBottom: '10px',
        },
    },

    p4: {

    },

    p5: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '200%',

        [styles.bp(1700)]: {
            fontSize: '12px',
        },
    },

    p6: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',
        textTransform: 'uppercase',

        [styles.bp(1700)]: {
            fontSize: '12px',
            lineHeight: '15px',
        },
        [styles.bp(700)]: {
            fontSize: '12px',
            lineHeight: '15px',
        },
    },

    p7: {
        fontWeight: '700',
        fontSize: '120px',
        lineHeight: '100%',
        textTransform: 'uppercase',

        [styles.bp(1700)]: {
            fontSize: '70px',
        },
        [styles.bp(1300)]: {
            fontSize: '50px',
        },
    },

    p8: {
        fontWeight: '700',
        fontSize: '60px',
        lineHeight: '73px',

        [styles.bp(1700)]: {
            fontSize: '50px',
            lineHeight: '61px',
        },
        [styles.bp(1300)]: {
            fontSize: '37px',
            lineHeight: '45px',
        },
        [styles.bp(1000)]: {
            fontSize: '30px',
            lineHeight: '37px',
        },
        [styles.bp(700)]: {
            fontSize: '26px',
            lineHeight: '32px',
        }
    },

    b1: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '88.4%',
        letterSpacing: '0.44em',
        textTransform: 'uppercase'
    },

    b2m: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',

        [styles.bp(1700)]: {
            fontSize: '12px',
            lineHeight: '15px',
        },
    },

    b3s: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',

        [styles.bp(1700)]: {
            fontSize: '12px',
            lineHeight: '15px',
        },
    },

    lf: {
        fontWeight: '700',
        fontSize: '9px',
        lineHeight: '100%',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',

        [styles.bp(1700)]: {
            fontSize: '8px',
        },
    },

    list: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '150%',
        marginBottom: '20px',

        '&:last-child': {
            marginBottom: '0'
        },

        li: {
            display: 'block',
            position: 'relative',
            marginBottom: '10px',
            paddingLeft: '20px',
        },

        [styles.bp(1700)]: {
            fontSize: '12px',
            marginBottom: '35px',

            li: {
                marginBottom: '8px',
            }
        },
        [styles.bp(1300)]: {
            marginBottom: '20px',
        },
        [styles.bp(1000)]: {
            marginBottom: '15px',
        },
    },

    ul: {
        li: {
            '&::before': {
                content: '""',
                display: 'block',
                ...styles.absolute('9px', 'auto', 'auto'),
                width: '14px',
                height: '1px',
                background: styles.colors.gray_dark,
                
                [styles.bp(1700)]: {
                    top: '8px'
                },
                [styles.bp(700)]: {
                    top: '6px'
                },
            }
        },
    },

    ol: {
        counterReset: 'list 0',

        li: {
            '&::before': {
                counterIncrement: 'list',
                content: 'counter(list)',
                display: 'block',
                ...styles.absolute('0.1em', 'auto', 'auto')
            }
        },
    },

    ult: {
        li: {
            marginBottom: '10px',
            paddingLeft: '20px',
            position: 'relative',

            '&::before': {
                content: '""',
                position: 'absolute',
                left: '0',
                top: '0.3em',
                right: 'auto',
                bottom: 'auto',
                width: '10px',
                height: '10px',
                borderStyle: 'solid',
                borderColor: `transparent transparent transparent ${styles.colors.blue}`,
                borderWidth: '5px 0px 5px 10px',
                boxSizing: 'border-box',
                background: 'transparent'
            }
        },

        [styles.bp(1700)]: {
            li: {
                marginBottom: '8px',
            }
        }
    },
    
    uls: {
        li: {
            marginBottom: '10px',
            paddingLeft: '15px',
            position: 'relative',

            '&::before': {
                content: '""',
                position: 'absolute',
                left: '0',
                top: '0.48em',
                right: 'auto',
                bottom: 'auto',
                width: '6px',
                height: '6px',
                background: styles.colors.cyan
            }
        },

        [styles.bp(1700)]: {
            li: {
                marginBottom: '8px',
            }
        }
    },

    ul2c: {
        [styles.bpm(1000)]: {
            columnCount: '2',
            
            'li': {
                overflow: 'hidden',
                WebkitColumnBreakInside: 'avoid',
                pageBreakInside: 'avoid',
                breakInside: 'avoid-column',
            }
        }
    }
};

fonts.p2u = {
    textTransform: 'uppercase',
    ...fonts.p2
};

fonts.p3u = {
    textTransform: 'uppercase',
    ...fonts.p3
};

fonts.b2u = {
    textTransform: 'uppercase',
    ...fonts.b2m
};

fonts.h5 = {
    ...fonts.p3u,

    [styles.bp(1700)]: {
        fontSize: '14px',
        lineHeight: '17px',
    },

    [styles.bp(700)]: {
        fontSize: '15px',
        lineHeight: '18px',
    },
};

fonts.h6 = {
    ...fonts.p3,

    [styles.bp(1700)]: {
        fontSize: '14px',
        lineHeight: '17px',
    },

    [styles.bp(700)]: {
        fontSize: '15px',
        lineHeight: '18px',
    },
};

export default fonts;
