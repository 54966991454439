import React from "react";
import { css, keyframes } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import Title from "../Title";
import logo from "../../images/svg/logo_full.svg";

const showEl = keyframes`
    from {
       transform: translate3d(0, 100px, 0);
       opacity: 0;
    }
    
    to {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
`;

const hideEl = keyframes`
    from {
         transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
    
    to {
          transform: translate3d(0, 0px, 0);
       opacity: 0;

    }
`;

const mobOverlays = [
    require('../../images/svg/mob/bnr-mob-overlay-1.svg'),
    require('../../images/svg/mob/bnr-mob-overlay-2.svg'),
    require('../../images/svg/mob/bnr-mob-overlay-3.svg'),
    require('../../images/svg/mob/bnr-mob-overlay-4.svg'),
    require('../../images/svg/mob/bnr-mob-overlay-5.svg'),
];

const css_style = css({
    '&__title-wrap': {
        transform: 'translateY(100px)',
        opacity: '0',
        animation: `${hideEl} 1s ease both`,
        animationDelay: `0ms`,
        textAlign: 'center'
    },

    '&__subtitle': {
        ...fonts.p2u,
        margin: '25px auto 0',
        maxWidth: '750px'
    },

    '&__content': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    '&__logo-wrap': {
        display: 'block',
        position: 'relative',
        width: '320px',
        margin: '0 auto 110px',
        transform: 'translateY(100px)',
        opacity: '0',
        animation: `${hideEl} 1s ease both`,
        animationDelay: `0ms`
    },

    '&__logo': {
        display: 'block',
        position: 'relative',
        width: '100%',
        height: 'auto'
    },

    '&__ai-overlay-wrap': {
        width: 'auto',
        height: '100%',
        ...styles.absolute('50%', 'auto', 'auto', '50%'),
        transform: 'translate(-50%, -50%)',
    },
    '&__ai-overlay': {
        display: 'block',
        position: 'relative',
        width: 'auto',
        margin: 'auto',
        height: '100%',
        zIndex: '5',
        opacity: '0',
        transform: 'scale(5)',
        pointerEvents: 'none'
    },

    '&._loaded._show &': {
        '&__logo-wrap': {
            animation: `${showEl} 1s ease both`,
            animationDelay: `${styles.blocksFadeDuration / 2}ms`
        },

        '&__title-wrap': {
            animation: `${showEl} 1s ease both`,
            animationDelay: `${styles.blocksFadeDuration}ms`
        },

        '&__ai-overlay': {
            opacity: '1',
            transform: 'scale(1)',
            transition: styles.transitions.type10
        },
    },

    '&._small &': {
        '&__logo-wrap': {
            width: '186px',
            marginBottom: '60px',
        },

        '&__title-wrap': {
            maxWidth: '960px',
            margin: '0 auto'
        },

        '&__subtitle': {
            maxWidth: '630px'
        },
    },

    [styles.bp(1700)]: {
        '&__logo-wrap': {
            width: '243px',
            marginBottom: '70px'
        },

        '&__subtitle': {
            marginTop: '20px',
        },

        '&._small &': {
            '&__logo-wrap': {
                width: '160px',
                marginBottom: '50px',
            },
        },
    },
    [styles.bp(1300)]: {
        '&__logo-wrap': {
            width: '181px',
            marginBottom: '40px'
        },

        '&__subtitle': {
            marginTop: '15px',
        },

        '&._small &': {
            '&__logo-wrap': {
                marginBottom: '40px',
            },
        },
    },
    [styles.bp(1000)]: {
        height: '900px',
    },
    [styles.bp(700)]: {
        height: '550px',

        '&__logo-wrap': {
            width: '138px',
            marginBottom: '30px'
        },

        '&._small &': {
            '&__logo-wrap': {
                width: '113px',
                marginBottom: '30px',
            },
        },
    },
});

class Banner extends React.Component {
    render() {
        const { mod, data, resolution, overlay } = this.props;
        const { styleMod='' } = data;
        const loadedMod = Object.keys(data).length ? '_loaded' : '';
        
        return (
            <section className={`${css_style} section ${mod} ${styleMod} ${loadedMod}`}>
                <div className={`${css_style}__ai-overlay-wrap`}>
                    {resolution.isMob && <img src={mobOverlays[overlay-1]} className={`${css_style}__ai-overlay`} alt='ai-overlay'/>}
                </div>

                <div className="inner-container">
                    <div className={`${css_style}__content`}>
                        <div className={`${css_style}__logo-wrap`}>
                            <img src={logo} className={`${css_style}__logo`} alt='logo'/>
                        </div>

                        <div className={`${css_style}__title-wrap`}>
                            {data.title &&
                                <Title data={data.title}/>
                            }

                            {data.subtitle &&
                                <h4 className={`${css_style}__subtitle`}>{require("html-react-parser")(data.subtitle)}</h4>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Banner;
