import React, {Component} from "react";
import {css} from "emotion";
import {Field} from "formik";
import styles from "../../../constants/styles";
import checkSvg from '../../../images/svg/check.svg';

const textarea = css({
    position: "relative",
    width: "100%",
    marginBottom: '20px',

    "&__input": {
        display: "block",
        backgroundColor: "transparent",
        height: '96px',
        width: "100%",
        padding: "4px 20px 20px 0",
        color: styles.colors.blue,
        borderBottom: `2px solid ${styles.colors.blue}`,
        boxSizing: 'border-box',
        fontFamily: 'montserrat',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '200%',
        resize: 'none',

        "::-webkit-input-placeholder": {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '200%',
            color: styles.colors.blue,
        },
        ":-ms-input-placeholder": {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '200%',
            color: styles.colors.blue,
        },
        "::-ms-input-placeholder": {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '200%',
            color: styles.colors.blue,
        },
        "::placeholder": {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '200%',
            color: styles.colors.blue,
        },

        '&._error': {
            borderBottom: `2px solid ${styles.colors.orange_light}`,
        },

        '&._valid': {
            borderBottom: `2px solid ${styles.colors.cyan}`,
        }
    },

    '&__label-wrap': {
        marginTop: '10px'
    },

    "&__input-wrap": {
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '14px',
            height: '12px',
            left: 'auto',
            right: '0',
            top: '12px',
            bottom: 'auto',
            background: `url(${checkSvg}) center center no-repeat`,
            backgroundSize: 'contain',
            transition: styles.transitions.default,
            opacity: '0'
        }
    },
    "&__input-wrap._valid": {
        '&::before': {
            opacity: '1'
        }
    },

    "&__input-wrap._error + &__label": {
        color: styles.colors.orange_light
    },

    "&__label": {
        display: "block",
        position: 'relative',
        marginTop: '10px',
        fontFamily: 'montserrat',
        fontWeight: '700',
        textTransform: 'uppercase',
        fontSize: '9px',
        lineHeight: '100%',
        letterSpacing: '0.1em',
        color: styles.colors.gray_dark
    }
});

class TextArea extends Component {
    renderItem = (field, form) => {
        const {label, placeholder} = this.props.data,
            isTouchEnd = form.touched[field.name],
            isErrorExist = isTouchEnd && form.errors[field.name],
            isValid = isTouchEnd && field.value && !isErrorExist,
            inputMod = isErrorExist ? '_error' : (isValid ? '_valid' : '');

        return (
            <React.Fragment>
                <div className={`${textarea}__input-wrap ${inputMod}`}>
                    <textarea
                        {...field}
                        className={`${textarea}__input`}
                        placeholder={placeholder}
                    />
                </div>

                <span className={`${textarea}__label`}>
                    {require("html-react-parser")(isErrorExist ? form.errors[field.name] : label)}
                </span>
            </React.Fragment>
        )
    };

    render() {
        const {counter, name} = this.props.data,
            input_counter = counter ? counter : "";

        return (
            <div className={textarea}>
                <Field name={`${name}${input_counter}`}>
                    {({field, form}) => (
                        this.renderItem(field, form)
                    )}
                </Field>
            </div>
        );
    }
}

export default TextArea;

