import React from "react";
import { css } from "emotion";
import { NavLink } from "react-router-dom";
import Title from "../Title";
import TextBlock from "../TextBlock";
import RotatedText from '../../components/RotatedText';
import Button from '../../components/Button';
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";

const mobOverlays = [
    require('../../images/svg/mob/content-img-overlay-1.svg'),
    require('../../images/svg/mob/content-img-overlay-2.svg'),
    require('../../images/svg/mob/content-img-overlay-3.svg'),
    require('../../images/svg/mob/content-img-overlay-4.svg'),
    require('../../images/svg/mob/content-img-overlay-5.svg')
];

const css_style = css({
    '&__title-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '620px'
    },

    '&__text-wrap': {
        position: 'relative',
        display: 'block',
        marginRight: 'auto',
        maxWidth: '500px'
    },

    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__content': {
        width: '100%',
        position: 'relative',
        marginRight: 'auto'
    },

    '&__col': {
        width: '50%',
        transitionDelay: `${styles.blocksFadeDuration}ms`,

        '&_left': {
            transform: 'translateY(-200px)',
            opacity: '0'
        },
        '&_right': {
            transform: 'translateY(200px)',
            opacity: '0'

        }
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        marginRight: 'auto'
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        maxWidth: '90%',
        width: '650px',
        maxHeight: '80vh',
        margin: '0 auto',

        'img': {
            position: 'relative',
            display: 'block',
            objectFit: 'contain',
            objectPosition: 'center center',
            width: '100%',
            height: '100%',
            maxHeight: '80vh',
        }
    },

    '&__ai-overlay': {
        ...styles.absolute(),
        width: '95%',
        zIndex: '5',
        opacity: '0',
        transform: 'scale(0)',
        pointerEvents: 'none'
    },


    '&._reverse &': {
        '&__ai-overlay': {
            width: '80%',
        },

        '&__col': {

            '&_left': {
                order: '2'
            },
            '&_right': {
                order: '1'
            }
        },

        '&__title-wrap': {
            maxWidth: '500px'
        },

        '&__img': {
            width: '420px',
        }
    },

    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        },

        '&__ai-overlay': {
            opacity: '1',
            transform: 'scale(1)',
            transition: styles.transitions.type7
        },

    },

    [styles.bp(1700)]: {
        '&__img': {
            width: '450px',
        },

        '&._reverse &': {
            '&__img': {
                width: '260px',
            }
        },
    },
    [styles.bp(1300)]: {
        '&__img': {
            width: '310px',
        },

        '&._reverse &': {
            '&__img': {
                width: '190px',
            }
        },
    },
    [styles.bp(1000)]: {
        '&__col': {
            transitionDelay: `0ms`,

            '&_left': {
                transform: 'translateY(-100px)',
            },
            '&_right': {
                transform: 'translateY(100px)',
            }
        },

        '&._show &': {
            '&__col': {
                transitionDelay: `0ms`,
            }
        },

        '&__img': {
            width: '270px',
        },

        '&._reverse &': {
            '&__img': {
                width: '160px',
            }
        },
    },

    [styles.bp(700)]: {
        '&._reverse &': {
            '&__col': {
                '&_left': {
                   width: '55%'
                },
                '&_right': {
                    width: '45%'
                }
            },

        },
    },

    [styles.bp(600)]: {
        '&__img': {
            width: '100%',
            maxWidth: 'none'
        },

        '&._reverse &': {
            '&__img': {
                width: '100%',
            },

            '&__ai-overlay': {
                transform: 'scale(0) rotate(90deg)',
                height: '80vw',
                width: 'auto'
            },

            '&__col': {
                width: '100%',

                '&_left': {
                    order: '1'
                },
                '&_right': {
                    order: '2'
                }
            }
        },

        '&._reverse._show &': {
            '&__ai-overlay': {
                transform: 'scale(1) rotate(90deg)',
            },
        },

        '&__table': {
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        },

        '&__col': {
            width: '100%',

            '&_right': {
                marginTop: '30px'
            }
        }
    }
});

const services_list_styles = css({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '70px',

    '&__item': {
        width: 'calc(100% / 3 - 20px)',
        maxWidth: '150px'
    },

    '&__text': {
        marginTop: '10px',
        color: styles.colors.gray_dark,
        transition: styles.transitions.default,
        ...fonts.p5,
    },

    '&__item:hover &__text': {
        color: styles.colors.cyan,
    },

    [styles.bp(1700)]: {
        marginTop: '50px',
    },

    [styles.bp(1300)]: {
        marginTop: '25px',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        '&__item': {
            width: '100%',
            maxWidth: 'none',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'flex-start',

            '&:last-of-type': {
                marginBottom: '0'
            }
        },

        '&__title-wrap': {
            minWidth: '130px',
            paddingRight: '20px'
        },

        '&__text': {
            marginTop: '0'
        },
    },

    [styles.bp(1000)]: {
        marginTop: '20px',

        '&__item': {
            width: '100%',
            maxWidth: 'none',
            marginBottom: '10px',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },

        '&__text': {
            marginTop: '0'
        },
    },

    [styles.bp(700)]: {
        '&__item': {
            marginBottom: '10px'
        },

    }
});

const services_benefits_styles = css({
    display: 'block',
    position: 'relative',
    marginTop: '40px',
    width: '100%',

    '&__list': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '540px',
    },

    '&__item': {
        width: 'calc(100% / 3 - 20px)'
    },

    '&__list._long &__item': {
        width: 'calc(100% / 2 - 20px)'
    },

    '&__title': {
        ...fonts.p3u,
        color: styles.colors.gray_dark,
        marginBottom: '20px',
        display: 'block',
        position: 'relative'
    },

    '&__subtitle': {
        ...fonts.p8,
        color: styles.colors.orange_light,
        marginBottom: '0 !important'
    },

    '&__text': {
        marginTop: '4px',
        ...fonts.p3
    }
});

class ContentBlock extends React.Component {
    renderList = (list) => {
        return (
            <div className={`${services_list_styles}`}>
                {
                    list.map((item, key) => {
                        const {title, text, link} = item,
                            titleData = {
                                text: title,
                                highlight: 'KIMI',
                                mod: 'p2u _reverse-highlight'
                            };

                        return (
                            <NavLink key={key} to={link} className={`${services_list_styles}__item`}>
                                <div className={`${services_list_styles}__title-wrap`}>
                                    <Title data={titleData}/>
                                </div>

                                <p className={`${services_list_styles}__text`}>
                                    {require("html-react-parser")(text)}
                                </p>
                            </NavLink>
                        )
                    })
                }
            </div>
        );
    };

    renderBenefits = (data) => {
        const isLongList = data.list.length > 3;
        const listMod = isLongList ? '_long' : '';

        return (
            <div className={`${services_benefits_styles}`}>

                {data.title &&
                    <div className={`${services_benefits_styles}__title`}>
                        {require("html-react-parser")(data.title)}
                    </div>
                }

                {data.list &&
                    <div className={`${services_benefits_styles}__list ${listMod}`}>
                        {
                            data.list.map((item, key) => {
                                const { title, text } = item;

                                return(
                                    <div key={key} className={`${services_benefits_styles}__item`}>
                                        <h5 className={`${services_benefits_styles}__subtitle`}>
                                            {require("html-react-parser")(title)}
                                        </h5>

                                        <p className={`${services_benefits_styles}__text`}>
                                            {require("html-react-parser")(text)}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    };

    render() {
        const { mod, data, styleMod='', resolution } = this.props;

        return (
            <section className={`${css_style} ${styleMod} section ${mod}`}>
                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__col ${css_style}__col_left`}>
                                <div className={`${css_style}__content`}>
                                    <div className={`${css_style}__title-wrap`}>
                                        {data.title &&
                                            <Title data={data.title}/>
                                        }
                                    </div>

                                    <div className={`${css_style}__text-wrap`}>
                                        {data.text_block &&
                                            <TextBlock data={data.text_block}/>
                                        }
                                    </div>
                                </div>

                                {data.services_list && this.renderList(data.services_list)}
                                {data.benefits && this.renderBenefits(data.benefits)}

                                {data.btn &&
                                    <Button link={data.btn.href} text={data.btn.text}/>
                                }
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right`}>
                                <div className={`${css_style}__img-wrap`}>
                                    {data.img && data.img.desk &&
                                        <picture className={`${css_style}__img`} >
                                            {data.img.mob && <source media="(max-width: 600px)" srcSet={data.img.mob}/>}
                                            <img src={data.img.desk} alt="service"/>
                                        </picture>
                                    }
                                </div>

                                {resolution.isMob && data.mob_overlay && <img src={mobOverlays[data.mob_overlay-1]} className={`${css_style}__ai-overlay`} alt='ai-overlay'/>}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContentBlock;
