function urls(location) {
  // let isProd = location.hostname.indexOf('localhost');
  //return (isProd === -1) ? urlsObj.backend : urlsObj.backend;
  return urlsObj.backend;
}

let isProd = window.location.hostname.indexOf('kimimo.eu');

let host = isProd !== -1 ? 'api.kimimo.eu' : 'kimimo-wp.tutmee-pro.ru';
let form = window.location.protocol + '//' + host +
    '/wp-json/sau/send-mail/v1/send';

host = window.location.protocol + '//' + host + '/wp-json/tutmee';
console.log(host);
const urlsObj = {
  backend: {
    cookie: host + '/cookie',
    menu: host + '/menu',
    homepage: host + '/homepage',
    contacts: host + '/contacts',
    service: host + '/service',
    about: host + '/about',
    kimieye: host + '/kimieye',
    kimitwin: host + '/kimitwin',
    kimidesign: host + '/kimidesign',
    technologies: host + '/technologies',
    news_list: host + '/news-list',
    news_page: host + '/news-page',
    article: host + '/article',
    form_handler: form,
  },

  frontend: {
    cookie: host + 'public/test-data/cookie.php',
    menu: host + 'public/test-data/menu.php',
    homepage: host + 'public/test-data/homepage.php',
    contacts: host + 'public/test-data/contacts.php',
    service: host + 'public/test-data/service.php',
    about: host + 'public/test-data/about.php',
    kimieye: host + 'public/test-data/kimieye.php',
    kimitwin: host + 'public/test-data/kimitwin.php',
    kimidesign: host + 'public/test-data/kimidesign.php',
    technologies: host + 'public/test-data/technologies.php',
    news_list: host + 'public/test-data/news-list.php',
    news_page: host + 'public/test-data/news-page.php',
    article: host + 'public/test-data/article.php',
    form_handler: '/form-handler.php',
  },
};

export default urls(window.location);
