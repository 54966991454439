import React from "react";
import { css } from "emotion";
import Title from "../Title";
import TextBlock from "../TextBlock";

import SvgMorphLink from "../SvgMorphLink";
import RotatedText from '../../components/RotatedText';

import styles from "../../constants/styles";

import { ReactComponent as AiSvg } from "../../images/svg/animate/ai.svg";
import { ReactComponent as MachineLearningSvg } from "../../images/svg/animate/machine-learning.svg";
import { ReactComponent as ComputerVisionSvg } from "../../images/svg/animate/computer-vision.svg";
import { ReactComponent as AdvancedAnalyticsSvg } from "../../images/svg/animate/advanced-analytics.svg";
import { ReactComponent as MathOptimization } from "../../images/svg/animate/math-optimization.svg";

const svgArray = {
    ai: <AiSvg/>,
    ml: <MachineLearningSvg/>,
    cv: <ComputerVisionSvg/>,
    al: <AdvancedAnalyticsSvg/>,
    mo: <MathOptimization/>,

};

const css_style = css({
    'h2': {
        maxWidth: '450px'
    },

    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },

    '&__row': {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },

    '&__content': {
        width: '100%',
        position: 'relative',
        maxWidth: '440px',
        marginRight: 'auto'
    },

    '&__col': {
        transform: 'translateY(100px)',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
        opacity: '0',

        '&_left': {
            width: 'calc(100% / 10 * 5)',

        },
        '&_right': {
            width: 'calc(100% / 10 * 4)',
        }
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        marginRight: 'auto'
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        maxWidth: '100%',
        maxHeight: '80vh',
        margin: '0 auto'
    },

    '&._show': {

    },
    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transform: 'translateY(0)',
            opacity: '1',

            '&_left': {
                transitionDelay: `${styles.blocksFadeDuration}ms`,

            },
            '&_right': {
                transitionDelay: `${styles.blocksFadeDuration + 250}ms`,
            }
        },
    },

    [styles.bp(1000)]: {
        '&__col': {
            transform: 'translateY(50px)',
            transitionDelay: `0ms`,
        },

        '&._show &': {
            '&__col': {
                '&_left': {
                    transitionDelay: `0ms`,

                },
                '&_right': {
                    transitionDelay: `250ms`,
                }
            },
        },
    },


    [styles.bp(700)]: {
        '&__col': {

            '&_left': {
                width: 'calc(100% / 10 * 6)',

            },
            '&_right': {
                width: 'calc(100% / 10 * 3)',
            }
        },
    },


    [styles.bp(500)]: {
        '&__row': {
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },

        '&__col': {
            width: '100%',

            '&_right': {
                marginTop: '15px'
            }
        },
    }
});


const technologies_list_style = css({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '100px',
    width: '90%',

    '&__item': {
        width: 'calc(25% - 20px)',
        maxWidth: '220px',
        transform: 'translateY(100px)',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
        opacity: '0',
    },

    '&._show &': {
        '&__item': {
            transition: styles.transitions.type6,
            transform: 'translateY(0)',
            opacity: '1',
            ...styles.setDelays({base: styles.blocksFadeDuration + 250, count: 4, delay: 250})
        },
    },

    [styles.bp(1700)]: {
        marginTop: '70px',

        '&__item': {
            maxWidth: '175px',
        },
    },

    [styles.bp(1000)]: {
        marginTop: '40px',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',

        '&__item': {
            width: '50%',
            maxWidth: 'none',
            paddingRight: '20px',
            marginBottom: '20px',
            transform: 'translateY(50px)',
            transitionDelay: `0ms`,
        },

        '&._show &': {
            '&__item': {
                ...styles.setDelays({base: 250, count: 4, delay: 250})
            },
        }
    },

    [styles.bp(700)]: {
        marginTop: '20px',

        '&__item': {
            width: '100%',
            paddingRight: '0',
            marginBottom: '15px'
        },
    }
});

class Technologies extends React.Component {
    render() {
        const { mod, data, resolution } = this.props;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__row`}>
                                <div className={`${css_style}__col ${css_style}__col_left`}>
                                    <div className={`${css_style}__content`}>
                                        {data.title &&
                                            <Title data={data.title}/>
                                        }

                                        {data.text_block &&
                                            <TextBlock data={data.text_block}/>
                                        }
                                    </div>
                                </div>

                                <div className={`${css_style}__col ${css_style}__col_right`}>
                                    {data.main_svg &&
                                        <SvgMorphLink link={data.main_svg.link} title={data.main_svg.title}>
                                            {svgArray[data.main_svg.svg]}
                                        </SvgMorphLink>
                                    }
                                </div>
                            </div>
                            <div className={`${css_style}__row`}>
                                {data.list &&
                                    <div className={`${technologies_list_style} ${mod}`}>
                                        {
                                            data.list.map((item, key) => {
                                                return (
                                                    <div key={key} className={`${technologies_list_style}__item`}>
                                                        <SvgMorphLink link={item.link} title={item.title} mod='_small'>
                                                            {svgArray[item.svg]}
                                                        </SvgMorphLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Technologies;
