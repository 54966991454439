import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import Banner from "../../components/Banner";
import ProductInfo from "../../components/ProductInfo";
import VideoBlock from "../../components/VideoBlock";
import ContentBlock from "../../components/ContentBlock";
import ListBlock from "../../components/ListBlock";
import ScrollContainer from "../../containers/ScrollContainer";
import Contacts from "../../components/Contacts";

import AIOverlay from "../../containers/AIOverlay";
import { ReactComponent as ProductPageSvg } from "../../images/svg/animate/product-page.svg";
import fetchQuery from "../../utils/fetch";

class KimiTwin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            product_info: {},
            video_block: {},
            gallery: {},
            headline: {},
            benefits: {},
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.kimitwin});
    }

    render() {
        const { banner, product_info, video_block, headline, benefits } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <ProductPageSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='2'/>
                    <ProductInfo id='product-info' data={product_info}/>
                    <VideoBlock id='video-block' data={video_block}/>
                    <ContentBlock id='content-block-1' data={headline}/>
                    <ListBlock id='benefits' data={benefits}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(KimiTwin);


