import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import AIOverlay from "../../containers/AIOverlay";
import ScrollContainer from "../../containers/ScrollContainer";
import Banner from "../../components/Banner";
import VideoBlock from "../../components/VideoBlock";
import ContentBlock from "../../components/ContentBlock";
import Technologies from "../../components/Technologies";
import LatestNews from "../../components/LatestNews";
import Contacts from "../../components/Contacts";
import { ReactComponent as AiHomepage } from "../../images/svg/animate/ai_homepage.svg";

import fetchQuery from '../../utils/fetch';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            video_block: {},
            service: {},
            technologies: {},
            latest_news: {}
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.homepage});
    }

    render() {
        const { banner, video_block, service, technologies, latest_news } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <AiHomepage/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='1'/>
                    <VideoBlock id='who-we-are' data={video_block}/>
                    <ContentBlock id='service' styleMod='_reverse' data={service}/>
                    <Technologies id='technologies' data={technologies}/>
                    <LatestNews id='latest-news' data={latest_news}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(HomePage);


