import React from "react";
import { css, keyframes } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import urls from "../../constants/urls";
import NewsItem from "../NewsItem";
import RotatedText from '../../components/RotatedText';
import Pagination from '../../components/Pagination';
import InnerScrollWrap from "../../containers/InnerScrollWrap";
import fetchQuery from '../../utils/fetch';

const showEl = keyframes`
    from {
       transform: translate3d(0, 50px, 0);
       opacity: 0;
    }
    
    to {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
`;

const css_style = css({
    '&__content': {
        display: 'block',
        position: 'relative',
        padding: '100px 0',
        width: 'calc(100% / 10 * 8)',
        margin: '0 auto'
    },

    '.container': {
        height: 'auto'
    },

    '&  &__aside-text-wrap': {
        ...styles.absolute('0', 'auto'),
        pointerEvents: 'none',
        zIndex: '1'
    },

    '&__title': {
        display: 'block',
        position: 'relative',
        ...fonts.p1,
        marginBottom: '90px',

        '&::before': {
            content: '""',
            ...styles.absolute('0.45em', 'calc(100% + 50px)', 'auto', 'auto'),
            display: 'block',
            height: '8px',
            width: '88px',
            background: styles.colors.cyan
        }
    },

    '&__list': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'flex-start',
        flexWrap: 'wrap'
    },

    '&__item': {
        display: 'block',
        position: 'relative',
        width: 'calc(50% - 10px)',
        paddingRight: '28px',
        marginBottom: '90px',
        transform: 'translate3d(0, 50px, 0)',
        opacity: '0',
        animation: `${showEl} 1s ease both`,
        ...styles.setDelays({base: 0, count: 6, delay: 150, type: 'animation'})
    },

    [styles.bp(1700)]: {
        '&__content': {
            padding: '70px 0',
        },

        '&__title': {
            marginBottom: '60px',

            '&::before': {
                height: '6px',
                width: '68px',
                right: 'calc(100% + 30px)'
            }
        },

        '&__item': {
            paddingRight: '20px',
            marginBottom: '60px',
        },
    },

    [styles.bp(1300)]: {
        '&__content': {
            padding: '30px 0',
        },

        '&__title': {
            marginBottom: '40px',

            '&::before': {
                height: '4px',
                width: '40px',
            }
        },

        '&__item': {
            marginBottom: '50px',
        },
    },

    [styles.bp(1000)]: {
        '&__content': {
            padding: '20px 0',
            width: '100%',
        },

        '&__title': {
            paddingLeft: '60px',

            '&::before': {
                right: 'auto',
                left: '0',
                width: '35px',
            }
        },

        '&__item': {
            width: 'calc(50% - 20px)',
            paddingRight: '0',
        },
    },

    [styles.bp(700)]: {
        '&__content': {
            padding: '0',
        },

        '&__item': {
            width: '100%',
            marginBottom: '30px',
        },

        '&__title': {
            marginBottom: '30px',
            paddingLeft: '0',

            '&::before': {
                position: 'relative',
                margin: '15px auto 15px 0',
                top: '0'
            }
        },
    }
});

class NewsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            news_list: {},
            page_count: 0,
            show: 6
        };

        this.newsList = React.createRef();
    }

    componentDidMount() {
        this.getNews(1);
    }

    getNews = (page) => {
        const { show } = this.state;

        return fetchQuery.bind(this)({
            url: urls.news_list,
            method: 'POST',
            customSuccess: this.successHandler,
            body: JSON.stringify({
                'current': page,
                'get': show
            })
        });
    };

    successHandler = (responseJson) => {
        const { show } = this.state;

        this.setState({
            news_list: responseJson.news_list,
            page_count: Math.ceil(responseJson.total_news_count / show)
        });
    };

    handlePageClick = data => {
        this.getNews(data.selected + 1);
        if (this.scrollWrap) this.scrollWrap.scrollTop();
    };

    render() {
        const { news_list, page_count } = this.state;
        const { mod, data, resolution, screensId, id } = this.props;
        const isShowed = mod === ' _show';

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className={`${css_style}__aside-text-wrap container`}>
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}
                </div>

                <InnerScrollWrap parentId={id} screensId={screensId} relatedRef={ref => (this.scrollWrap = ref)}  resolution={resolution} isShowed={isShowed}>
                    <div className="container">
                        <div className="inner-container">
                            <div className={`${css_style}__content`}>
                                <div className={`${css_style}__title-wrap`}>
                                    {data.title &&
                                    <h3 className={`${css_style}__title`}>{require("html-react-parser")(data.title)}</h3>
                                    }
                                </div>

                                <div ref={this.newsList} className={`${css_style}__list`}>
                                    {news_list.length &&
                                        news_list.map((item) => {
                                            return (
                                                <div key={item.id} className={`${css_style}__item`}>
                                                    <NewsItem data={item}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {page_count > 1 && (
                                    <Pagination
                                        page_count={page_count}
                                        on_page_change={this.handlePageClick}
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                </InnerScrollWrap>
            </section>
        )
    }
}

export default NewsList;
