import React from "react";
import {connect} from "react-redux";
import { css } from "emotion";
import TextBlock from "../TextBlock";
import RotatedText from '../../components/RotatedText';
import styles from "../../constants/styles";
import Title from "../Title";
import TechnologySlider from "../TechnologySlider";
import Button from '../../components/Button';

import { ReactComponent as MachineLearningSvg } from "../../images/svg/animate/machine-learning.svg";
import { ReactComponent as ComputerVisionSvg } from "../../images/svg/animate/computer-vision.svg";
import { ReactComponent as AdvancedAnalyticsSvg } from "../../images/svg/animate/advanced-analytics.svg";
import { ReactComponent as MathOptimization } from "../../images/svg/animate/math-optimization.svg";

import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";
import { PreventScroll } from "../../actions/PreventScroll";

const css_style = css({
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__col': {
        '&_left': {
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            width: 'calc(100% / 10 * 6 - 10px)',
            transform: 'translateY(-200px)',
            opacity: '0'
        }
    },

    '&__description': {
        display: 'block',
        position: 'relative',
        width: '80%',
        marginRight: 'auto'
    },

    '&__svg-wrap': {
        display: 'block',
        position: 'relative',
        width: '50%',
        marginBottom: '40px',

        'svg': {
            display: 'block',
            position: 'relative',
            width: 'calc(100% / 6 * 5)',
            height: 'auto',
            maxWidth: '670px',
            margin: '0 auto'
        }
    },

    '&__slider-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
    },

    '&__scroll-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        overflow: 'auto'
    },

    '&._show &': {
        '&__col': {
            '&_left': {
                transition: styles.transitions.type6,
                transitionDelay: `${styles.blocksFadeDuration}ms`,
                transform: 'translateY(0)',
                opacity: '1'
            }
        }
    },

    '&__close-info': {
        ...styles.closeBtn
    },

    'svg [class*="-step2"]': {
        opacity: 0
    },

    [styles.bpm(1300)]: {
        '&__col': {
            '&_right': {
                transitionDelay: `${styles.blocksFadeDuration}ms`,
                width: 'calc(100% / 10 * 4 - 10px)',
                transform: 'translateY(200px)',
                opacity: '0'
            }
        },

        '&._show &': {
            '&__col': {
                '&_right': {
                    transition: styles.transitions.type6,
                    transitionDelay: `${styles.blocksFadeDuration}ms`,
                    transform: 'translateY(0)',
                    opacity: '1'
                }
            }
        },
    },

    [styles.bp(1700)]: {
        '&__col': {
            '&_left': {
                width: 'calc(100% / 10 * 5 - 10px)',
            },
            '&_right': {
                width: 'calc(100% / 10 * 5 - 10px)',
            }
        },

        '&__description': {
            width: '90%',
        },
    },

    [styles.bp(1300)]: {
        '&__scroll-wrap': {
            padding: '0 20px'
        },

        '&__col': {
            '&_left': {
                width: '100%'
            },

            '&_right': {
                ...styles.fixed('0', 'auto'),
                width: `calc(100% - ${styles.width.menu1300})`,
                height: '100%',
                background: styles.colors.white,
                zIndex: '10',
                opacity: '0',
                transform: 'translateX(-100%)',
                pointerEvents: 'none',
                transition: 'transform 0s linear, opacity 1s ease',
                transitionProperty: 'transform, opacity',
                transitionDelay: '1s, 0s',

                '&._show': {
                    opacity: '1',
                    pointerEvents: 'auto',
                    transform: 'translateX(0)',
                    transitionDelay: '0s, 0s',
                }
            }
        },

        '&__description': {
            width: '100%',
        },

        '&__slider-wrap': {
            width: 'calc(100% / 12 * 8)',
            height: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '70px 0',
            overflow: 'auto'
        },

        '&__svg-wrap': {
            width: '120px',
            height: '96px',
            marginBottom: '20px',

            'svg': {
                display: 'block',
                position: 'relative',
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
            }
        },
    },

    [styles.bp(1000)]: {
        '&__col': {
            transitionDelay: `0ms`,

            '&_left': {
                transform: 'translateY(-100px)',
            },

            '&_right': {
                width: '100%',
            }
        },

        '&._show &': {
            '&__col': {
                '&_left': {
                    transitionDelay: `0ms`,
                }
            }
        },
    },

    [styles.bp(700)]: {
        '&__slider-wrap': {
            width: '100%',
            padding: '60px 40px'
        },
    },

    [styles.bp(500)]: {
        '&__slider-wrap': {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    }
});


class TechnologyInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showInfo: false
        };

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();
        this.morphIsInit = false;
        this.svg = this.svgContainer.current;
    }

    componentDidMount() {
        this.tl = new TimelineMax({repeat: -1, yoyo: true, paused: true, repeatDelay: 2});
    }

    componentDidUpdate() {
        const { mod, PreventScroll } = this.props;
        const { showInfo } = this.state;

        if (!this.svg) this.svg = this.svgContainer.current;

        if (!this.morphIsInit && this.svg) {
            this.morphIsInit = true;
            this.initMorph();
        }

        if (mod === ' _show') {
            this.tl.play();
        } else {
            this.tl.pause();

            if (showInfo) {
                this.setState({
                    showInfo: false
                });

                PreventScroll(false);
            }
        }
    }

    initMorph = () => {
        const objectsCount = this.svg.querySelectorAll('[class*="-step1"]').length;

        for (var i = 1; i <= objectsCount; i++) {
            const morphFrom = this.svg.querySelector(`.js-object${i}-step1`),
                morphTo = this.svg.querySelector(`.js-object${i}-step2`),
                fill = (morphTo && morphTo.length) ? morphTo.getAttribute('fill') : false;

            if (morphFrom) {
                let morphObj = {
                    ease: Elastic.easeInOut.config(1, 0.75)
                };

                morphObj.morphSVG = morphTo ? morphTo : this.svg.querySelector(`.js-object${i}-step1`);
                morphObj.fill = fill ? fill : morphObj.morphSVG.getAttribute('fill');

                this.tl.to(morphFrom, 2, morphObj, 'now');
            }
        }
    };

    renderProductSvg = (name) => {
        switch (name) {
            case 'learning': {
                return <MachineLearningSvg/>;
            }
            case 'vision': {
                return <ComputerVisionSvg/>;
            }
            case 'analytics': {
                return <AdvancedAnalyticsSvg/>;
            }
            case 'optimization': {
                return <MathOptimization/>;
            }
            default: {
                return false
            }
        }
    };

    toggleInfo = (val) => {
        const { PreventScroll } = this.props;

        PreventScroll(val);

        this.setState({
            showInfo: val
        });
    };

    render() {
        const
            { showInfo } = this.state,
            { mod, data, resolution } = this.props,
            { description, slider, button_more } = data,
            infoMod = showInfo ? '_show' : '',
            isSmallDevice = resolution.isMob || resolution.isTablet;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__col ${css_style}__col_left`}>
                                {description &&
                                    <div className={`${css_style}__description`}>
                                        <div ref={this.svgContainer} className={`${css_style}__svg-wrap`}>
                                            {description.svg_component_name &&
                                                this.renderProductSvg(description.svg_component_name)
                                            }
                                        </div>

                                        {description.title &&
                                            <Title data={description.title}/>
                                        }

                                        {description.text_block &&
                                            <TextBlock data={description.text_block}/>
                                        }
                                    </div>
                                }

                                {isSmallDevice && button_more && <Button text={button_more.text} onClickHandler={this.toggleInfo.bind(this, true)}/>}
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right ${infoMod}`}>
                                {slider &&
                                    <div className={`${css_style}__slider-wrap`}>
                                        <div className={`${css_style}__scroll-wrap`}>
                                            <TechnologySlider data={slider} resolution={resolution}/>
                                        </div>

                                        {isSmallDevice && <div className={`${css_style}__close-info`} onClick={this.toggleInfo.bind(this, false)}/>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        PreventScroll: val => dispatch(PreventScroll(val)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(TechnologyInfo);

