import { CHANGE_ACTIVE_BLOCK } from "../actions/ChangeActiveBlock";
import { PREVENT_SCROLL } from "../actions/PreventScroll";

const initialState = {
    id: null,
    prevent: false
};

export function scroll(state = initialState, action) {
    switch (action.type) {
        case CHANGE_ACTIVE_BLOCK: {
            return {...state, id: action.payload};
        }
        case PREVENT_SCROLL: {
            return {...state, prevent: action.payload};
        }
        default: {
            return state
        }
    }
}

