import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import TextBlock from "../TextBlock";
import Button from '../../components/Button';

const css_style = css({
    display: 'block',
    position: 'relative',
    width: '100%',

    '&__img-wrap': {
        width: '100%',
        marginBottom: '50px'
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        width: '100%',
    },

    '&__title-wrap': {
        marginBottom: '30px'
    },

    '&__title': {
        ...fonts.p2,
    },

    '&__date': {
        display: 'block',
        ...fonts.p6,
        color: styles.colors.blue,
        marginTop: '10px'
    },

    [styles.bp(1700)]: {
        '&__img-wrap': {
            marginBottom: '30px'
        },
        '&__title-wrap': {
            marginBottom: '25px'
        },
    },

    [styles.bp(1300)]: {
        '&__title-wrap': {
            marginBottom: '20px'
        },
    },

    [styles.bp(700)]: {
        '&__img-wrap': {
            marginBottom: '20px'
        },
        '&__title-wrap': {
            marginBottom: '15px'
        },
    },
});

class NewsItem extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <div className={`${css_style}`}>
                <div className={`${css_style}__img-wrap`}>
                    {data.img && <img src={data.img} className={`${css_style}__img`} alt=''/>}
                </div>

                <div className={`${css_style}__title-wrap`}>
                    {data.title && <h4 className={`${css_style}__title`}>{require("html-react-parser")(data.title)}</h4>}
                    {data.date && <span className={`${css_style}__date`}>{require("html-react-parser")(data.date)}</span>}
                </div>

                <div className={`${css_style}__text-wrap`}>
                    {data.text_block && <TextBlock data={data.text_block}/>}
                </div>

                {data.button_more && <Button link={data.button_more.link} text={data.button_more.text}/>}
            </div>
        )
    }
}

export default NewsItem;
