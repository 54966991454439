import React from "react";
import { css  } from "emotion";
import styles from "../../constants/styles";
import {changeActiveLink} from "../../actions/MenuActions";
import {connect} from "react-redux";

const css_style = css({
    [styles.bpm(1000)]: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '',
        transition: `opacity ${styles.blocksFadeDuration}ms ease`,
        opacity: '0',
        zIndex: '1',
        transitionDelay: '0s',

        "&._show": {
            opacity: '1',
            zIndex: '2',
            transitionDelay: `${styles.blocksFadeDuration}ms`,
        },
    }
});

class ScrollWrap extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { children, resolution, show } = this.props,
            chowChanged = show !== nextProps.show,
            dataChanged = children.props.data !== nextProps.children.props.data,
            resolutionChanged = (resolution.isMob !== nextProps.resolution.isMob) || (resolution.isTablet !== nextProps.resolution.isTablet);

        return (chowChanged || dataChanged || resolutionChanged);
    }

    render() {
        const
            { show, changeActiveLink, id, children, relatedRef, resolution, screensId } = this.props,
            mod = show  ? ' _show' : '';

        if (show && !resolution.isMob) changeActiveLink(id);

        return (
            <div ref={relatedRef} className={`${css_style + mod}`}>
                {React.cloneElement(children, {mod: mod, resolution: resolution, screensId: screensId})}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeActiveLink: id => dispatch(changeActiveLink(id)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(ScrollWrap);
