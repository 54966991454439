import React from "react";
import { css } from "emotion";
import TextBlock from "../TextBlock";
import RotatedText from '../../components/RotatedText';
import styles from "../../constants/styles";
import Title from "../Title";

import { ReactComponent as KimiEyeSvg } from "../../images/svg/animate/kimieye.svg";
import { ReactComponent as KimiTwinSvg } from "../../images/svg/animate/kimitwin.svg";
import { ReactComponent as KimiDesignSvg } from "../../images/svg/animate/kimidesign.svg";
import { ReactComponent as AiSvg } from "../../images/svg/animate/ai.svg";

import { TimelineMax } from "../../plugins/gsap";
import { Elastic } from "../../plugins/gsap/EasePack";
import MorphSVGPlugin from "../../plugins/gsap/MorphSVGPlugin";

const css_style = css({
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__col': {
        transitionDelay: `${styles.blocksFadeDuration}ms`,

        '&_left': {
            width: 'calc(100% / 10 * 6 - 10px)',
            transform: 'translateY(-200px)',
            opacity: '0'
        },
        '&_right': {
            width: 'calc(100% / 10 * 4 - 10px)',
            transform: 'translateY(200px)',
            opacity: '0'

        }
    },

    '&__svg-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',

        'svg': {
            display: 'block',
            position: 'relative',
            width: 'calc(100% / 6 * 5)',
            height: 'auto',
            maxWidth: '670px',
            margin: '0 auto'
        }
    },

    '&._reverse': {

    },

    '&._reverse &': {
        '&__table': {
            flexDirection: 'row-reverse'
        },

        '&__col': {
            width: '50%',
        },

        '&__svg-wrap': {
            'svg': {
                width: '80%',
                maxWidth: '550px',
            }
        },
    },

    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        }
    },

    'svg [class*="-step2"]': {
        opacity: 0
    },

    [styles.bp(1700)]: {
        '&__col': {
            '&_left': {
                width: 'calc(50% - 10px)',
            },

            '&_right': {
                width: 'calc(50% - 10px)',

            }
        },
    },

    [styles.bp(1000)]: {
        '&__col': {
            transitionDelay: `0ms`,

            '&_left': {
                transform: 'translateY(-100px)',
            },
            '&_right': {
                transform: 'translateY(100px)',
            }
        },

        '&._show &': {
            '&__col': {
                transitionDelay: `0ms`,
            }
        },
    },

    [styles.bp(700)]: {
        '&__table, &._reverse &__table': {
            flexDirection: 'column-reverse',
        },

        '&__col, &._reverse &__col': {
            width: '100%',

            '&_left': {
                marginTop: '20px'
            }
        },

        '&__svg-wrap, &._reverse &__svg-wrap': {
            'svg': {
                width: '75%',
                maxWidth: '205px',
            }
        },
    }
});


class ProductInfo extends React.Component {
    constructor(props) {
        super(props);

        this.morphSVGIncluded = MorphSVGPlugin ? true : false;
        this.svgContainer = React.createRef();
        this.morphIsInit = false;
    }

    componentDidMount() {
        this.svg = this.svgContainer.current;
        this.tl = new TimelineMax({repeat: -1, yoyo: true, paused: true});

    }

    componentDidUpdate() {
        const { mod } = this.props;

        if (!this.morphIsInit) {
            this.morphIsInit = true;
            this.initMorph();
        }

        if (mod === ' _show') {
            this.tl.play();
        } else {
            this.tl.pause();
        }
    }

    initMorph = () => {
        const objectsCount = this.svg.querySelectorAll('[class*="-step1"]').length;

        for (var i = 1; i <= objectsCount; i++) {
            const morphFrom = this.svg.querySelector(`.js-object${i}-step1`),
                morphTo = this.svg.querySelector(`.js-object${i}-step2`),
                fill = (morphTo && morphTo.length) ? morphTo.getAttribute('fill') : false;

            if (morphFrom) {
                let morphObj = {
                    ease: Elastic.easeInOut.config(1, 0.75)
                };

                morphObj.morphSVG = morphTo ? morphTo : this.svg.querySelector(`.js-object${i}-step1`);
                morphObj.fill = fill ? fill : morphObj.morphSVG.getAttribute('fill');

                this.tl.to(morphFrom, 2, morphObj, 'now');
            }
        }
    };

    renderProductSvg = (name) => {
        switch (name) {
            case 'kimieye': {
                return <KimiEyeSvg/>;
            }
            case 'kimitwin': {
                return <KimiTwinSvg/>;
            }
            case 'kimidesign': {
                return <KimiDesignSvg/>;
            }
            case 'ai': {
                return <AiSvg/>;
            }
            default: {
                return false
            }
        }
    };

    render() {
        const { mod, data, styleMod='', resolution } = this.props;

        return (
            <section className={`${css_style} ${styleMod} section ${mod}`}>
                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__table`}>
                            <div className={`${css_style}__col ${css_style}__col_left`}>
                                <div ref={this.svgContainer} className={`${css_style}__svg-wrap`}>
                                    {data.svg_component_name &&
                                        this.renderProductSvg(data.svg_component_name)
                                    }
                                </div>
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right`}>
                                {data.title &&
                                    <Title data={data.title}/>
                                }


                                {data.text_block &&
                                    <TextBlock data={data.text_block}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ProductInfo;
