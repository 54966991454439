const styles = {
    blocksFadeDuration: 1000,

    breakpoints: {
        '1700': 1700,
        '1500': 1500,
        '1300': 1300,
        '1050': 1050,
        '1000': 1000,
        '900': 900,
        '700': 700,
        '600': 600,
        '500': 500,
    },

    bp: (val) => `@media (max-width: ${styles.breakpoints[val]}px)`,
    bpm: (val) => `@media (min-width: ${styles.breakpoints[val]+1}px)`,
    bpb: (from, to) => `@media (min-width: ${styles.breakpoints[from]+1}px) and (max-width: ${styles.breakpoints[to]}px)`,

    width: {
        menu1920: '240px',
        menu1700: '190px',
        menu1300: '160px'
    },

    colors: {
        black: '#000',
        white: '#fff',
        cyan: '#74C8AF',
        gray_dark: '#333F48',
        gray_light: '#F4F5F0',
        orange: '#FF6A39',
        orange_light: '#E9A655',
        blue: '#869CAE',
        green: '#9EB356'
    },

    fontFamily: {
        ms: 'Montserrat'
    },

    transitions: {
        default: 'all 0.3s ease',
        ease1: 'all 1s ease',
        ease2: 'all 0.5s ease',
        ease3: 'all 0.7s ease',
        type2: 'all 500ms cubic-bezier(0.4,0,0.2,1)',
        type3: 'all 300ms cubic-bezier(0.4,0,0.2,1)',
        type4: 'all 700ms cubic-bezier(0.4,0,0.2,1)',
        type5: 'transform 3.5s cubic-bezier(0.18, 1, 0.21, 1) 0.3s,opacity 3.5s cubic-bezier(0.18, 1, 0.21, 1)',
        type6: 'transform 2s cubic-bezier(0.18, 1, 0.21, 1) 0.3s,opacity 2s cubic-bezier(0.18, 1, 0.21, 1)',
        type7: 'transform 1.5s cubic-bezier(0.18, 1, 0.21, 1) 0.3s,opacity 1.5s cubic-bezier(0.18, 1, 0.21, 1)',
        type8: 'all 200ms cubic-bezier(0.4,0,0.2,1)',
        type9: 'all 700ms cubic-bezier(0.4,0,0.2,1)',
        type10: 'transform 1.5s cubic-bezier(0.18, 1, 0.21, 1), opacity 1.5s cubic-bezier(0.18, 1, 0.21, 1)',
    },

    setDelays: (data) => {
        const { base, count, delay, type='transition' } = data;
        let stylesObj = {};

        for (let i = 1; i <= count; i++) {
            stylesObj[`&:nth-of-type(${i}n)`] = {
                [`${type}Delay`]: `${base + (delay * i)}ms`
            }
        }

        return stylesObj;
    },

    absolute: (top='0',right='0',bottom='0',left='0') => {
        return {
            position: 'absolute',
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            margin: 'auto'
        }
    },

    fixed: (top='0',right='0',bottom='0',left='0') => {
        return {
            position: 'fixed',
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            margin: 'auto'
        }
    },

    rh: (mlt=1) => {
       return {
           '&::after': {
               content: '""',
               display: 'block',
               paddingTop: `${100 * mlt}%`
           }
       }
    }
};

styles.isMob = () => window.innerWidth <= styles.breakpoints[1000];
styles.isTablet = () => (window.innerWidth > styles.breakpoints[1000]) && (window.innerWidth <= styles.breakpoints[1300]);

styles.closeBtn = {
    display: 'block',
    position: 'absolute',
    top: 'auto',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    width: '94px',
    height: '94px',
    cursor: 'pointer',
    background: styles.colors.cyan,
    zIndex: '10',

    '&::before, &::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        margin: 'auto',
        height: '2px',
        width: '40px',
        transformOrigin: '50% 50%',
        background: styles.colors.white,
        transition: styles.transitions.default
    },

    '&::before': {
        transform: 'rotate(-45deg)'
    },

    '&::after': {
        transform: 'rotate(45deg)'
    },

    '&:hover': {
        '&::before, &::after': {
            background: styles.colors.gray_dark
        }
    },

    '&._light': {
        background: styles.colors.white,

        '&::before, &::after': {
            background: styles.colors.gray_dark,
        },

        '&:hover': {
            '&::before, &::after': {
                background: styles.colors.cyan
            }
        },
    },

    [styles.bp(1300)]: {
        width: '60px',
        height: '60px',

        '&::before, &::after': {
            width: '30px',
        },
    },

    [styles.bp(700)]: {
        width: '50px',
        height: '50px',

        '&::before, &::after': {
            width: '20px',
        },
    }
};

styles.slickDots = {
    position: 'relative',
        marginTop: '20px',
        bottom: '0',
        
        'li': {
        margin: '0 3px',
            
            'button': {
            '&::before': {
                opacity: '1',
                    transition: styles.transitions.default
            }
        },
        
        '&.slick-active': {
            'button': {
                '&::before': {
                    color: styles.colors.cyan
                }
            },
        }
    }
};


export default styles;
