import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import Title from "../Title";
import TextBlock from "../TextBlock";
import {changeAIStep} from "../../actions/ChangeAIStepActions";
import {changeMenuTheme} from "../../actions/MenuActions";
import {connect} from "react-redux";
import YouTube from 'react-youtube';
import Button from '../../components/Button';
import RotatedText from '../../components/RotatedText';

const css_style = css({
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    '&__video-overlay': {
        width: '100%',
        height: '100%',
        ...styles.absolute(),
        background: styles.colors.cyan,
        transition: styles.transitions.default,
        zIndex: '5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '0',
        pointerEvents: 'none',

        '&._show': {
            opacity: '1',
            pointerEvents: 'auto'
        }
    },

    '&__video-container': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        width: '80%',
        height: '100%',
        padding: '100px 0'
    },
    
    '&__video-wrap': {
        position: 'relative',
        display: 'block',
        width: '100%',
        paddingBottom: '56.25%',
        paddingTop: '25px',
        height: '0',
    
        'iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            
            '&:-webkit-full-screen': {
                zIndex: '999 !important'
            },
            '&:-moz-full-screen': {
                zIndex: '999 !important'
            },
            '&:-ms-fullscreen': {
                zIndex: '999 !important'
            },
            '&:fullscreen': {
                zIndex: '999 !important'
            }
        },
    },

    '&__col': {
        transitionDelay: `${styles.blocksFadeDuration}ms`,

        '&_left': {
            width: 'calc(100% / 10 * 4)',
            maxWidth: '460px',
            transform: 'translateY(200px)',
            opacity: '0'
        },
        '&_right': {
            width: 'calc(100% / 10 * 6)',
            transform: 'translateY(-200px)',
            opacity: '0'
        }
    },

    '&__title-wrap': {
        display: 'block',
        position: 'relative',
        maxWidth: '350px'
    },

    '&__col_left._full-width, &__col_left._full-width &__title-wrap': {
        maxWidth: 'none'
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        maxWidth: '680px',
        marginRight: 'auto'
    },

    '&__img': {
        display: 'block',
        position: 'relative',
        width: '460px',
        maxWidth: '90%',
        maxHeight: '80vh',
        margin: '0 auto'
    },

    '&__play-btn': {
        display: 'block',
        position: 'relative',
        width: '215px',
        maxWidth: '90%',
        margin: '0 auto',
        opacity: '0',
        transform: 'scale(0)',
        zIndex: '5'
    },

    '&__close-video': {
        ...styles.closeBtn
    },

    '&._show &': {
        '&__col': {
            transition: styles.transitions.type6,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            transform: 'translateY(0)',
            opacity: '1'
        },

        '&__play-btn': {
            opacity: '1',
            transform: 'scale(1)',
            transition: styles.transitions.type7
        },

    },

    [styles.bp(1700)]: {
        '&__img': {
            width: '360px',
        },
    },

    [styles.bp(1300)]: {
        '&__img': {
            width: '280px',
        },

        '&__col': {
            '&_left': {
                width: '50%',
            },
            '&_right': {
                width: '50%',
            }
        },

        '&__video-container': {
            padding: '70px 0'
        },
    },

    [styles.bp(1000)]: {
        '&__video-container': {
            width: '100%',
        },

        '&__play-btn': {
            width: '215px',
            marginBottom: '-65px'
        },

        '&__img': {
            width: '230px',
        },

        '&__col': {
            transitionDelay: `0ms`,

            '&_left': {
                transform: 'translateY(100px)',
            },
            '&_right': {
                transform: 'translateY(-100px)',
            }
        },

        '&._show &': {
            '&__col': {
                transitionDelay: `0ms`,
            },
        },

        '&__video-overlay': {
            position: 'fixed',

            '.container': {
                height: '100%'
            }
        }
    },

    [styles.bp(700)]: {
        '&__img-wrap': {
            maxWidth: '280px',
            marginRight: 'auto',
            marginLeft: 'auto',
        },

        '&__play-btn': {
            ...styles.absolute('-20px','0','auto','auto'),
            width: '160px',
            marginBottom: '0'
        },

        '&__img': {
            width: '205px',
            marginRight: 'auto',
            marginLeft: '0'
        },

        '&__table': {
            flexWrap: 'wrap'
        },

        '&__col': {
            width: '100%',
            maxWidth: 'none',

            '&_right': {
                marginTop: '30px'
            }
        },

        '&__video-container': {
            padding: '60px 0'
        },
    }
});

class VideoBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showVideo: false
        };

        this.videoPlayer = null;
        this._isMounted  = false;
    }

    componentDidMount() {
        this.playBtn = document.querySelectorAll('.js-object13-step0')[0];
        this.initShowPlayer();
        this._isMounted  = true;
    }

    componentDidUpdate() {
        const
            videoIsOpen = this.state.showVideo,
            screenIsShowed =  this.props.mod === ' _show',
            mod = (videoIsOpen && screenIsShowed) ? '_light' : '_dark';

        this.props.changeMenuTheme(mod);

        if (videoIsOpen && screenIsShowed) {
            setTimeout(() => {
                if (this._isMounted) this.props.changeStep('step0');
            }, 200);
        }

        if (!videoIsOpen && screenIsShowed) {
            if (this.videoPlayer) this.videoPlayer.pauseVideo();
        }
    }

    componentWillUnmount() {
        this._isMounted  = false;
    }

    initShowPlayer = () => {
        if (this.playBtn) {
            this.playBtn.addEventListener("click", () => {
                this.playVideo();
            });
        }
    };

    playVideo = () => {
        this.setState({
            showVideo: true
        });
        this.videoPlayer.playVideo();
    };

    renderVideo = (data) => {
        const { id, lang } = data;
        
        const defaultOpts = {
            height: '100%',
            width: '100%',

            playerVars: {
                rel: 0,
                hl: lang
            }
        };
        
        if (data.player_vars) defaultOpts.playerVars =  {
            ...defaultOpts.playerVars,
            ...data.player_vars
        };
        
        return (
            <YouTube
                containerClassName={`${css_style}__video-wrap`}
                className={`${css_style}__video`}
                videoId={id}
                opts={defaultOpts}
                onReady={this._onReady}
            />
        )
    };
    
    _onReady = (e) => {
        this.videoPlayer = e.target;
    };

    closeVideo = () => {
        const { id } = this.props;

        this.setState({
            showVideo: false
        });

        this.props.changeStep(id);
    };

    render() {
        const { mod, data, resolution } = this.props;
        const { showVideo } = this.state;
        const widthMod = data.full_width ? '_full-width' : '';

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className={`${css_style}__video-overlay ${showVideo ? '_show' : ''}`}>
                    <div className="container">
                        {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                        <div className="inner-container">
                            <div className={`${css_style}__video-container`}>
                                {data.video && this.renderVideo(data.video)}

                                <div className={`${css_style}__close-video _light`} onClick={this.closeVideo}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__table`}>

                            <div className={`${css_style}__col ${css_style}__col_left ${widthMod}`}>
                                {data.title &&
                                    <div className={`${css_style}__title-wrap`}>
                                        <Title data={data.title}/>
                                    </div>
                                }

                                {data.text_block &&
                                    <TextBlock data={data.text_block}/>
                                }

                                {data.btn &&
                                    <Button link={data.btn.href} text={data.btn.text}/>
                                }
                            </div>

                            <div className={`${css_style}__col ${css_style}__col_right`}>
                                <div className={`${css_style}__img-wrap`}>
                                    {resolution.isMob && <img onClick={this.playVideo} src={require(`../../images/svg/mob/play.svg`)} className={`${css_style}__play-btn`} alt='service'/>}
                                    {data.poster && <img src={data.poster} className={`${css_style}__img`} alt='service'/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStep: step => dispatch(changeAIStep(step)),
        changeMenuTheme: theme => dispatch(changeMenuTheme(theme))
    }
};

export default connect(
    null,
    mapDispatchToProps
)(VideoBlock);

