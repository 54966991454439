import React from "react";
import { css } from "emotion";
import Title from "../Title";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import ColumnSlider from '../../components/ColumnSlider';

const css_style = css({
    '&__content': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },

    '&__table': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '60px'
    },

    '&__col': {
        width: 'calc(50% - 10px)'
    },

    '.slick-slide': {
        transform: 'translateX(100px)',
        opacity: '0',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
    },

    '&._show': {
        '.slick-slide': {
            transition: styles.transitions.type7,
            transform: 'translateX(0)',
            opacity: '1',
            ...styles.setDelays({base: styles.blocksFadeDuration, count: 5, delay: 150})
        },
    },

    [styles.bp(1700)]: {
        '&__table': {
            marginBottom: '20px'
        },
    },

    [styles.bp(1300)]: {
        '&__table': {
            flexWrap: 'wrap'
        },
        '&__col': {
            width: '100%',
        },
    },

    [styles.bp(1000)]: {
        '.slick-slide': {
            transform: 'translateX(50px)',
            transitionDelay: `0ms`,
        },

        '&._show': {
            '.slick-slide': {
                ...styles.setDelays({base: 0, count: 5, delay: 150})
            },
        },
    }
});

const css_stats = css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&__item': {
        width: 'calc(50% - 10px)',
        maxWidth: '260px'
    },

    '&__title': {
        ...fonts.h2,
        color: styles.colors.cyan,

        '&:last-of-type': {
            marginBottom: '0'
        }
    },

    '&__text': {
        ...fonts.p3,
        marginBottom: '0px'
    },

    [styles.bp(1300)]: {
        alignItems: 'flex-start',

        '&__item': {
            maxWidth: 'none',
            display: 'flex',
            alignItems: 'flex-start'
        },
        '&__title': {
            marginTop: '-0.1em'
        },

        '&__text': {
            paddingLeft: '10px',
            maxWidth: '230px'
        },
    },

    [styles.bp(700)]: {
        alignItems: 'flex-start',

        '&__item': {
            flexDirection: 'column'
        },
        '&__title': {
            marginTop: '0'
        },

        '&__text': {
            paddingLeft: '0',
            maxWidth: 'none'
        },
    }
});

class OurSkills extends React.Component {
    render() {
        const { mod, data } = this.props;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    <div className="inner-container">
                        <div className={`${css_style}__content`}>
                            <div className={`${css_style}__table`}>
                                <div className={`${css_style}__col ${css_style}__col_left`}>
                                    <div className={`${css_style}__title-wrap`}>
                                        {data.title &&
                                            <Title data={data.title}/>
                                        }
                                    </div>
                                </div>
                                <div className={`${css_style}__col ${css_style}__col_right`}>

                                    <div className={`${css_stats}`}>
                                        {data.stats &&
                                            data.stats.map((item, key) => {
                                                return (
                                                    <div key={key} className={`${css_stats}__item`}>
                                                        {item.title &&
                                                            <h2 className={`${css_stats}__title`}>{require("html-react-parser")(item.title)}</h2>
                                                        }
                                                        {item.text &&
                                                            <h6 className={`${css_stats}__text`}>{require("html-react-parser")(item.text)}</h6>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {data.slider &&
                                <ColumnSlider list={data.slider}/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default OurSkills;
