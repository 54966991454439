import { combineReducers } from "redux";

import { pageOverlayAI } from "./pageOverlayAI";
import { menu } from "./menu";
import { scroll } from "./scroll";
import { resolution } from "./resolution";
import { cache } from "./cache";

export const rootReducer = combineReducers({
    cache: cache,
    pageOverlayAI: pageOverlayAI,
    menu: menu,
    scroll: scroll,
    resolution: resolution
});

