import { SAVE_IN_CACHE } from "../actions/SaveInCache"

const initialState = {

};

export function cache(state = initialState, action) {
    switch (action.type) {
        case SAVE_IN_CACHE: {
            return {...state, ...action.payload};
        }
        default: {
            return state
        }
    }
}
