import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "emotion";
import {connect} from "react-redux";
import {changeAIStep} from "../../actions/ChangeAIStepActions";

import urls from "../../constants/urls";
import styles from '../../constants/styles';
import fonts from '../../constants/fonts';

import FormContactsUs from '../../components/FormContactsUs';
import Title from '../../components/Title';
import markerSvg from '../../images/svg/place.svg';
import Map from '../../components/Map';

import {SaveInCache} from "../../actions/SaveInCache";
import {PreventScroll} from "../../actions/PreventScroll";
import fetchQuery from "../../utils/fetch";

const css_style = css({
    '&__table': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        maxWidth: '1680px',
        paddingRight: '10%'
    },

    '&__col': {
        position: 'relative',

        '&_left': {
            width: 'calc(100% / 11 * 6)',
            padding: '0 50px',

        },
        '&_right': {
            width: 'calc(100% / 11 * 5)',
        }
    },

    '&__animate-wrap': {
        transitionDelay: `${styles.blocksFadeDuration}ms`,
        transform: 'translateY(100px)',
        opacity: '0'
    },

    '&__img-wrap-overlay': {
        display: 'block',
        position: 'absolute',
        left: '50%',
        top: '0',
        right: '0',
        bottom: '0',
        margin: 'auto',
        pointerEvents: 'none',
        width: '110%',
        height: '110%',
        zIndex: '20',
        borderColor: styles.colors.white,
        borderStyle: 'solid',
        borderWidth: '400px',
        borderRadius: '50%',
        transform: 'translateX(-50%)',
        transitionDelay: `${styles.blocksFadeDuration}ms`,
    },

    '&__img-wrap': {
        display: 'block',
        position: 'relative',
        width: '100%',
        maxWidth: '600px',
        zIndex: '3',
        borderRadius: '50%',
        overflow: 'hidden',
        margin: '0 auto',
        cursor: 'pointer',

        '&:after': {
            content: '""',
            paddingTop: '100%',
            display: 'block',
            position: 'relative'
        },

        '&:before': {
            content: '""',
            display: 'block',
            ...styles.absolute(),
            width: '50px',
            height: '65px',
            margin: 'auto',
            zIndex: '2',
            background: `url(${markerSvg}) center center no-repeat`,
            backgroundSize: 'contain',
            transform: 'translateY(-50%)'
        }
    },

    '&__img-wrap:hover &__img': {
        transform: 'scale(1.1)'
    },

    '&__img': {
        display: 'block',
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        zIndex: '1',
        transition: styles.transitions.type2,
    },
    
    '&__text, &__link': {
        ...fonts.p3
    },
    
    '&__description &': {
        '&__text': {
            marginBottom: '0',
        },
    },
    
    '&__description': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: '20px',
    },
    
    '&__links': {
        position: 'relative',
        width: '100%',
        maxWidth: '500px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '20px',
    },
    
    '&__link': {
        display: 'inline-block',
        color: styles.colors.orange,
        transition: styles.transitions.default,

        '&:hover': {
            color: styles.colors.cyan
        }
    },
    
    '&__link-wrap': {
        marginBottom: '10px',
        width: '100%',
    
        '&:last-child': {
            marginBottom: '0'
        }
    },
    
    
    '&__link-wrap &': {
        '&__link': {
            marginBottom: '0'
        },
    },
    
    '&__links._cols &__link-wrap': {
        width: 'calc(50% - 10px)',
    },
    
    '&__map-overlay': {
        display: 'block',
        ...styles.absolute(),
        width: '100%',
        height: '100%',
        zIndex: '20',
        background: styles.colors.white,

        opacity: '0',
        transform: 'translateX(100%)',
        pointerEvents: 'none',

        transition: 'transform 0s linear, opacity 1s ease',
        transitionProperty: 'transform, opacity',
        transitionDelay: '1s, 0s',


        '&._show': {
            opacity: '1',
            pointerEvents: 'auto',
            transform: 'translateX(0)',
            transitionDelay: '0s, 0s',
        }
    },

    '&__close-map': {
        ...styles.closeBtn
    },

    '&._show': {

    },
    '&._show &': {
        '&__img-wrap-overlay': {
            transition: styles.transitions.type4,
            transitionDelay: `${styles.blocksFadeDuration}ms`,
            borderWidth: '0px',
        },

        '&__animate-wrap': {
            transition: styles.transitions.type6,
            transform: 'translateY(0)',
            opacity: '1',

            '&:nth-of-type(1n)': {
                transitionDelay: `${styles.blocksFadeDuration}ms`,
            },
            '&:nth-of-type(2n)': {
                transitionDelay: `${styles.blocksFadeDuration + 150}ms`,
            },
            '&:nth-of-type(3n)': {
                transitionDelay: `${styles.blocksFadeDuration + 300}ms`,
            }
        },
    },

    '&__ai-overlay': {
        ...styles.absolute(),
        width: '80%',
        zIndex: '5',
        opacity: '0',
        transform: 'scale(0)',
        pointerEvents: 'none'
    },

    [styles.bp(1700)]: {
        '&__row': {
            marginBottom: '10px'
        },

        '&__img-wrap': {
            maxWidth: '412px',

            '&:before': {
                width: '42px',
                height: '56px',
            }
        },
    },

    [styles.bp(1300)]: {
        '&__img-wrap': {
            maxWidth: '300px',

            '&:before': {
                width: '33px',
                height: '44px',
            }
        },
    },

    [styles.bp(1000)]: {
        '&__img-wrap': {
            '&:before': {
                width: '30px',
                height: '40px',
            }
        },
    
        '&__link-wrap': {
            width: '100%',
        },

        '&__animate-wrap': {
            transitionDelay: `0ms`,
            transform: 'translateY(50px)'
        },

        '&__img-wrap-overlay': {
            transitionDelay: `0ms`,
        },

        '&._show &': {
            '&__img-wrap-overlay': {
                transitionDelay: `0ms`,
            },

            '&__ai-overlay': {
                opacity: '1',
                transform: 'scale(1)',
                transition: styles.transitions.type7
            },

            '&__animate-wrap': {
                '&:nth-of-type(1n)': {
                    transitionDelay: `0ms`,
                },
                '&:nth-of-type(2n)': {
                    transitionDelay: `150ms`,
                },
                '&:nth-of-type(3n)': {
                    transitionDelay: `300ms`,
                }
            },
        },

        '&__map-overlay': {
            position: 'fixed'
        },
    },

    [styles.bp(700)]: {
        '&__img-wrap': {
            '&:before': {
                width: '27px',
                height: '36px',
            }
        },

        '&__table': {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '0 40px'
        },

        '&__col': {
            width: '100%',

            '&_left': {
                padding: '0',
                margin: '35px auto 0',
                maxWidth: '245px',
            },
        },


        '&__ai-overlay': {
            width: '100%',
        },
    },

    [styles.bp(500)]: {
        '&__table': {
            padding: '0 20px'
        },
    }
});

class Contacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMap: false
        };

        this.mapIsInit = false;
        this._isMounted = false;
    }

    componentDidMount() {
        const { cache, id } = this.props;

        this._isMounted = true;

        if (!cache[id]) {
            return fetchQuery.bind(this)({url: urls.contacts, customSuccess: this.fetchSuccess});
        }
    }
    
    fetchSuccess = (responseJson) => {
        const { id, SaveInCache } = this.props;
        
        if (this._isMounted) {
            let newData = {};
            newData[id] = {...responseJson};
        
            SaveInCache(newData);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { mod } = this.props,
            mapIsOpen = this.state.showMap,
            screenIsShowed =  this.props.mod === ' _show';
        
        if (mapIsOpen && screenIsShowed) {
            setTimeout(() => {
                if (this._isMounted) this.props.changeStep('step0');
            }, 200);
        }

        if (mod && !this.mapIsInit)  this.mapIsInit = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showMap = () => {
        this.setState({
            showMap: true
        });
    
        this.toggleScroll(true);
    };

    closeMap = () => {
        const { id } = this.props;

        this.setState({
            showMap: false
        });

        this.props.changeStep(id);
        this.toggleScroll(false);
    };
    
    toggleScroll = (val) => {
        const { PreventScroll } = this.props;
        const { showMap } = this.state;
        if (showMap !== val) PreventScroll(val);
    };

    renderDescription = (description) => {
        return description.map((item, key) => {
            const textIsArray = Array.isArray(item.text);

            return (
                <div key={key} className={`${css_style}__row`}>
                    {
                        textIsArray
                            ?
                                item.text.map((item, key) => {
                                    return <span key={key} className={`${css_style}__text`}>{require("html-react-parser")(item)}</span>
                                })
                            :
                                <a className={`${css_style}__link`} href={item.link}>{require("html-react-parser")(item.text)}</a>
                    }
                </div>
            )
        })
    };
    
    renderLinks = (links) => {
        const countMod = links.length >= 4 ? '_cols' : '';
        
        return (
            <div className={`${css_style}__links ${countMod}`}>
                {
                    links.map((item, key) => {
                        return (
                            <div key={key} className={`${css_style}__link-wrap`}>
                                {
                                    item.type === 'nav'
                                        ?
                                        <NavLink to={item.link} className={`${css_style}__link`}>{require("html-react-parser")(item.text)}</NavLink>
                                        :
                                        <a href={item.link} target='_blank' rel="noopener noreferrer" className={`${css_style}__link`}>{require("html-react-parser")(item.text)}</a>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    render() {
        const
            { mod, id, resolution } = this.props,
            { title, description, links, map, img, ty, button_more, form } = this.props.cache[id] || {},
            { showMap } = this.state;

        return (
            <section className={`${css_style} section ${mod} js-contact-us`}>
                <div className="container">
                    <div className={`${css_style}__map-overlay ${showMap ? '_show' : ''}`}>
                        {this.mapIsInit && map && <Map data={map}/>}

                        <div className={`${css_style}__close-map`} onClick={this.closeMap}/>
                    </div>

                    <div className={`${css_style}__table`}>
                        <div className={`${css_style}__col ${css_style}__col_left`}>
                            <div className={`${css_style}__img-wrap`} onClick={this.showMap}>
                                <div className={`${css_style}__img-wrap-overlay`}/>
    
                                {img && img.desk &&
                                    <picture className={`${css_style}__img`} >
                                        {img.mob && <source media="(max-width: 600px)" srcSet={img.mob}/>}
                                        <img src={img.desk} alt="map"/>
                                    </picture>
                                }
                            </div>

                            {resolution.isMob && <img src={require(`../../images/svg/mob/contacts-overlay.svg`)} className={`${css_style}__ai-overlay`} alt='ai-overlay'/>}
                        </div>

                        <div className={`${css_style}__col ${css_style}__col_right`}>
                            <div className={`${css_style}__animate-wrap`}>
                                {title && <Title data={title}/>}
                            </div>

                            <div className={`${css_style}__animate-wrap`}>
                                {description &&
                                    <div className={`${css_style}__description`}>
                                        {
                                            description.map((item, key) => {
                                                return <span key={key} className={`${css_style}__text`}>{require("html-react-parser")(item)}</span>
                                            })
                                        }
                                    </div>
                                }
                                {links &&
                                    this.renderLinks(links)
                                }
                            </div>

                            <div className={`${css_style}__animate-wrap`}>
                                {form && ty && button_more && <FormContactsUs formData={form} data={ty} buttonText={button_more.text}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = store => {
    return {
        cache: store.cache
    }
};

const mapDispatchToProps = dispatch => {
    return {
        PreventScroll: val => dispatch(PreventScroll(val)),
        changeStep: step => dispatch(changeAIStep(step)),
        SaveInCache: obj => dispatch(SaveInCache(obj))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts);

