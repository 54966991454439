import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";

const parameters = {
    width: 29,
    height: 2,
    spacing: 6,
    barColor: styles.colors.cyan
};

const css_style = css({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '69px',
    height: '58px',
    background: styles.colors.white,
    pointerEvents: 'auto',

    '&__hamburger': {
        display: 'block',
        position: 'relative',
        width: parameters.width,
        height: `${parameters.height + parameters.spacing * 2}`,
    },

    '&__bar': {
        display: 'block',
        position: 'relative',
        width: parameters.width,
        height: parameters.height,
        background: parameters.barColor,
        transition: 'all 0ms 300ms',

        '&::before, &::after': {
            content: '""',
            width: 'inherit',
            height: 'inherit',
            background: parameters.barColor,
            willChange: 'transform'
        },

        '&::before': {
            ...styles.absolute('auto',0,parameters.spacing),
            transition: 'bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)',
        },

        '&::after': {
            ...styles.absolute(parameters.spacing, 0, 'auto'),
            transition: 'top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)'
        }
    },

    '&._active &': {
        '&__bar': {
            background: 'transparent',

            '&::before': {
                bottom: '0',
                transform: 'rotate(-45deg)',
                transition: 'bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1)'
            },

            '&::after': {
                top: '0',
                transform: 'rotate(45deg)',
                transition: 'top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1)'
            }
        },
    },

    [styles.bpm(1000)]: {
        display: 'none'
    },

    [styles.bp(1000)]: {
        width: '60px',
        height: '50px',
    }
});

class Hamburger extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

    }

    toggleHamburger = () => {
        const { toggleNav } = this.props;
        const { active } = this.state;

        this.setState({
            active: !active
        });

        toggleNav(!active);
    };

    closeHamburger = () => {
        const { toggleNav } = this.props;

        this.setState({
            active: false
        });

        toggleNav(false);
    };

    render() {
        const mod = this.state.active ? '_active' : '';

        return (
            <div className={`${css_style} ${mod}`} onClick={this.toggleHamburger}>
                <div className={`${css_style}__hamburger`}>
                    <div className={`${css_style}__bar`}/>
                </div>
            </div>
        )
    }
}

export default Hamburger;
