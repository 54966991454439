import React from "react";
import {connect} from "react-redux";

import urls from "../../constants/urls";
import AIOverlay from "../../containers/AIOverlay";
import ScrollContainer from "../../containers/ScrollContainer";
import Banner from "../../components/Banner";
import ProductInfo from "../../components/ProductInfo";
import ContentBlock from "../../components/ContentBlock";
import TechnologyInfo from "../../components/TechnologyInfo";
import Contacts from "../../components/Contacts";
import { ReactComponent as OurTechnologiesSvg } from "../../images/svg/animate/our-technologies.svg";
import fetchQuery from "../../utils/fetch";

class Technologies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            product_info: {},
            machine_learning: {},
            computer_vision: {},
            advanced_analytics: {},
            math_optimization: {},
            content_block_2: {},
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.technologies});
    }

    render() {
        const { banner, product_info, machine_learning, computer_vision, advanced_analytics, math_optimization, content_block_2 } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <OurTechnologiesSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='3'/>
                    <ProductInfo id='product-info' styleMod='_reverse' data={product_info}/>
                    <TechnologyInfo id='machine-learning' data={machine_learning}/>
                    <TechnologyInfo id='advanced-analytics' data={advanced_analytics}/>
                    <TechnologyInfo id='computer-vision' data={computer_vision}/>
                    <TechnologyInfo id='math-optimization' data={math_optimization}/>
                    <ContentBlock id='content-block-2' styleMod='_reverse' data={content_block_2}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(Technologies);

