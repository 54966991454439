import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styles from "./constants/styles";
import CookieWarning from "./components/CookieWarning";
import Menu from "./components/Menu";
import HomePage from "./views/HomePage";
import AiAsAService from "./views/AiAsAService";
import WhoWeAre from "./views/WhoWeAre";
import KimiEye from "./views/KimiEye";
import KimiTwin from "./views/KimiTwin";
import KimiDesing from "./views/KimiDesing";
import Technologies from "./views/Technologies";
import NewsPage from "./views/NewsPage";
import ArticlePage from "./views/ArticlePage";
import NewsListPage from "./views/NewsListPage";
import {connect} from "react-redux";
import {changeActiveBlock} from "../src/actions/ChangeActiveBlock";
import {PreventScroll} from "./actions/PreventScroll";

import './App.css';

const routes = [
    { path: '/', Component: HomePage, exact: true },
    { path: '/service', Component: AiAsAService },
    { path: '/about', Component: WhoWeAre },
    { path: '/kimieye', Component: KimiEye },
    { path: '/kimitwin', Component: KimiTwin },
    { path: '/kimidesign', Component: KimiDesing },
    { path: '/technologies', Component: Technologies },
    { path: '/news', Component: NewsListPage, exact: true },
    { path: '/news/:id', Component: NewsPage },
    { path: '/::id', Component: ArticlePage },
];

let delay = { enter: styles.blocksFadeDuration * 2, exit: styles.blocksFadeDuration * 2 };

class App extends React.Component {
    constructor(props) {
        super(props);

        this.props.history.listen((location) => {
            const { changeActiveBlock, PreventScroll } = this.props;
            const hash = location.hash;

            changeActiveBlock(hash.length ? hash.replace('#', '') : null);
            PreventScroll(false);

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "instant"
                });
            }, styles.blocksFadeDuration)
        });
    }

    render() {
        const
            { location } = this.props.history,
            currentKey = location.pathname;

        return (
            <React.Fragment>
                <CookieWarning/>
                <Menu location={currentKey}/>

                <TransitionGroup component="main" className={"page-main"}>
                    <CSSTransition key={currentKey} timeout={delay} classNames={"page"} appear>
                        <div className="page-wrap">
                            <Switch location={location}>
                                {routes.map(({ path, Component, exact=false }) => (
                                    <Route key={path} exact={exact} path={path} component={Component} />
                                ))};
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        PreventScroll: val => dispatch(PreventScroll(val)),
        changeActiveBlock: id => dispatch(changeActiveBlock(id))
    }
};

export default withRouter(connect(null, mapDispatchToProps)(App));
