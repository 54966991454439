export const CHANGE_ACTIVE_LINK = 'CHANGE_ACTIVE_LINK';
export const CHANGE_MENU_THEME = 'CHANGE_MENU_THEME';

export const changeActiveLink = (id) => dispatch => {
    dispatch({
        type: CHANGE_ACTIVE_LINK,
        payload: id
    });
};

export const changeMenuTheme = (theme) => dispatch => {
    dispatch({
        type: CHANGE_MENU_THEME,
        payload: theme
    });
};

