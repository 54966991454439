import React, { Component } from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import Collapse from "@kunukn/react-collapse";
import { ReactComponent as TutMeeSvg } from "../../images/svg/tutmee.svg";

const css_style = css({
    display: 'block',
    position: 'relative',
    paddingTop: '40px',
    opacity: '0',
    pointerEvents: 'none',
    transition: styles.transitions.default,
    zIndex: '3',

    '&__copyright': {
        ...fonts.p5,
        color: styles.colors.blue,
        display: 'block',
        position: 'relative',
        marginBottom: '10px'
    },
    '&__logo-wrap': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    '&__svg-wrap': {
        width: '18px',
        height: '29px',

        'svg': {
            display: 'block',
            position: 'relative',
            maxWidth: '100%',
            maxHeight: '100%',
        }
    },
    '&__text': {
        color: styles.colors.blue,
        display: 'block',
        position: 'relative',
        paddingLeft: '8px',
        fontSize: '10px',
        lineHeight: '12px',
        maxWidth: '130px'
    },

    '.tutmee_log_svg0, .tutmee_log_svg1': {
        transition: styles.transitions.default
    },

    '.tutmee_log_svg0': {
        fill: styles.colors.cyan,
    },
    '.tutmee_log_svg1': {
        fill: styles.colors.blue,
    },

    '&__logo-wrap:hover': {
        '.tutmee_log_svg0': {
            fill: styles.colors.blue,
        },
        '.tutmee_log_svg1': {
            fill: styles.colors.cyan,
        },
    },

    '&._show': {
        opacity: '1',
        pointerEvents: 'auto',
        transitionDelay: '.5s'
    },

    [styles.bp(700)]: {
        paddingTop: '30px'
    }
});

class TutMeeLogo extends Component {
    render() {
        const { show='' } = this.props;
        const showLogo = show.length;

        return (
            <Collapse className='collapse-css-transition logo-collapse-wrap' isOpen={showLogo}>
                <div className={`${css_style} ${show}`}>
                    <span className={`${css_style}__copyright`}>© 2019 KIMIMO</span>
    
                    <a rel="noopener noreferrer" href="https://tutmee.ru/" target="_blank" className={`${css_style}__logo-wrap`}>
                        <div className={`${css_style}__svg-wrap`}>
                            <TutMeeSvg/>
                        </div>
    
                        <span className={`${css_style}__text`}>LTD Tutmee Web development Agency</span>
                    </a>
                </div>
            </Collapse>
        )
    }
}

export default TutMeeLogo;
