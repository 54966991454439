import React, { Component } from "react";
import { css } from "emotion";
import ReactPaginate from "react-paginate";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import arrowIcon from '../../images/svg/arrow-slider_white.svg';

let paginate = css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "&__item": {
        '&._active, &:hover': {
            color: styles.colors.cyan
        },

        '&._disabled': {
            opacity: '0.3',
            pointerEvents: 'none'
        }
    },


    "&__item:first-of-type &": {
        "&__link": {
            padding: '0px',
            marginRight: '10px'
        },
    },

    "&__item:last-of-type &": {
        "&__link": {
            padding: '0px',
            marginLeft: '10px'
        },
    },

    "&__link": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "0 7px",
        ...fonts.p5
    },

    '&__arrow': {
        width: '38px',
        height: '38px',
        background: styles.colors.cyan,
        transition: styles.transitions.default,

        '&::before': {
            content: '""',
            position: 'relative',
            display: 'block',
            width: '100%',
            height: '100%',
            margin: 'auto',
            background: `url(${arrowIcon}) center center no-repeat`,
            backgroundSize: '40% 40%',
            transformOrigin: '50%, 50%',
            opacity: '1'
        },

        '&_left': {
            '&::before': {
                transform: 'scaleX(-1)'
            },
        },

        '&:hover': {
            background: styles.colors.gray_dark
        }
    },

    '&__dotted': {
        padding: "0 7px",
    },

    [styles.bp(700)]: {
        justifyContent: 'center'
    }
});

class Pagination extends Component {
    render() {
        const { page_count, on_page_change } = this.props;

        return (
            <ReactPaginate
                previousLabel={
                    <div className={`${paginate}__arrow  ${paginate}__arrow_left`} />
                }
                nextLabel={
                    <div className={`${paginate}__arrow ${paginate}__arrow_right`} />
                }
                breakLabel={
                    <div className={`${paginate}__dotted`}>
                        ...
                    </div>
                }
                breakClassName={`${paginate}__item`}
                pageCount={page_count}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={on_page_change}
                containerClassName={paginate}
                subContainerClassName={"pages pagination"}
                pageClassName={`${paginate}__item`}
                previousClassName={`${paginate}__item`}
                nextClassName={`${paginate}__item`}
                pageLinkClassName={`${paginate}__link`}
                previousLinkClassName={`${paginate}__link`}
                nextLinkClassName={`${paginate}__link`}
                activeClassName={"_active"}
                disabledClassName={"_disabled"}
            />
        );
    }
}
export default Pagination;
