import React from "react";
import {connect} from "react-redux";
import urls from "../../constants/urls";
import ScrollContainer from "../../containers/ScrollContainer";
import Article from "../../components/Article";
import Contacts from "../../components/Contacts";
import AIOverlay from "../../containers/AIOverlay";
import {ReactComponent as ArticleSvg} from "../../images/svg/animate/ai_homepage.svg";
import fetchQuery from "../../utils/fetch";

class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            content: {}
        };
    }
    
    componentDidMount() {
        const { match } = this.props;
    
    
        return fetchQuery.bind(this)({
            url: urls.article,
            method: 'POST',
            body: JSON.stringify({
                "id": match.params.id
            })
        });
    }
    
    render() {
        const { content } = this.state;
        const { resolution, location } = this.props;
        
        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock={false}>
                        <ArticleSvg/>
                    </AIOverlay>
                }
                
                <ScrollContainer resolution={resolution} location={location}>
                    <Article id='article' data={content}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(NewsPage);
