import React from "react";
import { css } from "emotion";
import { NavLink } from "react-router-dom";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";

const css_style = css({
    position: 'relative',
    display: 'inline-block',
    marginTop: '30px',
    color: styles.colors.orange,
    padding: '10px 50px 10px 0',
    background: 'transparent',
    cursor: 'pointer',
    ...fonts.b1,

    '&::after, &::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-0.1em',
        right: '0',
        bottom: '0',
        left: 'auto',
        margin: 'auto',
        width: '70px',
        height: '2px',
        background: styles.colors.orange,
        transformOrigin: '100% 50%',
        transition: styles.transitions.default,
        transitionProperty: 'transform, width, right',
        transitionDelay: '0s, 0.3s, 0.3s'
    },

    '&:hover': {
        '&::after, &::before': {
            width: '10px',
            right: '30px',
            transitionTimingFunction: 'ease',
            transitionDelay: '0.3s, 0s, 0s'
        },
        '&::after': {
            transform: 'rotate(45deg)',
        },
        '&::before': {
            transform: 'rotate(-45deg)',
        },
    },

    [styles.bp(1300)]: {
        marginTop: '25px',
    },

    [styles.bp(700)]: {
        marginTop: '20px',
    }
});

class Button extends React.Component {
    renderLink = (link, text) => {
        if (link) {
            return <NavLink to={link} className={css_style}>{text}</NavLink>
        } else {
            return <button type="submit" className={css_style} onClick={this.onClick}>{text}</button>
        }
    };

    onClick = () => {
        const { onClickHandler } = this.props;

        if (onClickHandler) onClickHandler();
    };

    render() {
        const { link, text } = this.props;

        return (
            this.renderLink(link, text)
        )
    }
}

export default Button;
