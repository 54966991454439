import { CHANGE_AI_STEP } from "../actions/ChangeAIStepActions"

const initialState = {
    step: null
};

export function pageOverlayAI(state = initialState, action) {
    switch (action.type) {
        case CHANGE_AI_STEP: {
            return {...state, step: action.payload};
        }
        default: {
            return state
        }
    }
}
