import React from "react";
import {connect} from "react-redux";
import { css } from "emotion";
import { PreventScroll } from "../../actions/PreventScroll";
import { changeActiveBlock } from "../../actions/ChangeActiveBlock";
import Swipe from 'react-easy-swipe';
import ScrollEdge from "../../components/ScrollEdge";
import styles from "../../constants/styles";

const css_style = css({
    display: 'block',
    position: 'relative',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: '100%',
    width: '100%',
    zIndex: '5',

    '&__swipe-wrap': {
        display: 'block',
        position: 'relative',
        height: '100%',
        width: '100%',
    },

    [styles.bpm(1000)]: {
        '&._prevent': {
            pointerEvents: 'none'
        }
    }
});

class InnerScrollWrap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readyToScroll: true,
        };

        this.lastSwipeVal = 0;
        this.scrollTimer = null;
        this.scrollWrap = React.createRef();
        this.ScrollEdge = React.createRef();
    }

    componentDidMount() {
        const { relatedRef } = this.props;
        if (relatedRef) relatedRef(this);
    }

    componentDidUpdate(prevProps) {
        const { isShowed, PreventScroll, resolution } = this.props;

        PreventScroll(isShowed);
        if (!resolution.isMob && prevProps.isShowed !== isShowed) this.switchScrollReady(isShowed);
    }

    switchScrollReady = (isShowed) => {
        clearTimeout(this.scrollTimer);

        if (isShowed) {
            this.scrollTimer = setTimeout(() => {
                this.setState({
                    readyToScroll: true
                })
            }, styles.blocksFadeDuration + 500)
        } else {
            this.setState({
                readyToScroll: false
            })
        }
    };

    scrollTop = () => {
        const { resolution } = this.props;
        let scrollWrap = this.scrollWrap.current,
            start = scrollWrap.scrollTop;

        if (!resolution.isMob) {
            let change = 0 - start,
                currentTime = 0,
                duration = 300,
                increment = 20;

            let animateScroll = () => {
                currentTime += increment;
                scrollWrap.scrollTop = this.easeInOutQuad(currentTime, start, change, duration);
                if (currentTime < duration) setTimeout(animateScroll, increment);
            };

            animateScroll();
        } else {
            window.scrollTo({
                top: scrollWrap.offsetParent.offsetTop + scrollWrap.offsetTop,
                behavior: "smooth"
            });
        }
    };

    easeInOutQuad = (t, b, c, d) => {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    onScrollHandler = (e) => {
        if (this.ScrollEdge.current) {
            const direction = e.deltaY < 0 ? 'up' : 'down';
            this.ScrollEdge.current.onEdge(direction)
        }
    };

    onSwipeMove = (e) => {
        if (this.ScrollEdge.current) {
            const direction = this.lastSwipeVal < e.y ? 'up' : 'down';
            this.lastSwipeVal = e.y;
            this.ScrollEdge.current.onEdge(direction);
        }
    };

    renderWrap = () => {
        const { readyToScroll } = this.state;
        const { children, screensId, parentId, changeActiveBlock, resolution } = this.props;
        const scrollWrapMod = readyToScroll ? '' : '_prevent';

        return (
            <div onWheel={this.onScrollHandler} ref={this.scrollWrap} className={`${css_style} ${scrollWrapMod}`}>
                { !resolution.isMob &&
                    <ScrollEdge changeBLock={changeActiveBlock} parentRef={this.scrollWrap} parentId={parentId} screensId={screensId} ref={this.ScrollEdge} />
                }

                { children }
            </div>
        )
    };

    switchWrap = () => {
        const { resolution } = this.props;

        if (!resolution.isMob) {
            return (
                <Swipe className={`${css_style}__swipe-wrap`}
                    onSwipeMove={this.onSwipeMove}
                >
                    {this.renderWrap()}
                </Swipe>
            )
        } else {
            return (
                this.renderWrap()
            )
        }
    };

    render() {
        return (
            this.switchWrap()
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        PreventScroll: val => dispatch(PreventScroll(val)),
        changeActiveBlock: id => dispatch(changeActiveBlock(id)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(InnerScrollWrap);
