import React from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import { NavLink } from "react-router-dom";
import TextBlock from "../TextBlock";
import Title from "../Title";
import Button from '../../components/Button';
import RotatedText from '../../components/RotatedText';


const css_style = css({
    '&__content': {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },

    '&__title': {
        marginBottom: '20px',
        transition: styles.transitions.default,
        ...fonts.p2
    },

    '&__list': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '60px 0'
    },

    '&__item, &__title-wrap, &__button-wrap': {
        transitionDelay: `${styles.blocksFadeDuration}ms`,
        transform: 'translateY(200px)',
        opacity: '0'
    },

    '&__item': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'calc(100% / 3 - 40px / 3)',
        color: styles.colors.gray_dark,

        '&:nth-of-type(1n)': {
            '&::before, &::after': {
                background: styles.colors.cyan
            },
        },

        '&:nth-of-type(2n)': {
            '&::before, &::after': {
                background: styles.colors.blue
            },
        },

        '&:nth-of-type(3n)': {
            '&::before, &::after': {
                background: styles.colors.orange_light
            },
        }
    },
    '&__item:nth-of-type(1n) &__line': {
        '&::before, &::after': {
            background: styles.colors.cyan
        }
    },
    '&__item:nth-of-type(2n) &__line': {
        '&::before, &::after': {
            background: styles.colors.blue
        }
    },
    '&__item:nth-of-type(3n) &__line': {
        '&::before, &::after': {
            background: styles.colors.orange_light
        }
    },
    '&__line': {
        display: 'block',
        position: 'relative',
        width: '20px',
        height: '80px',
        marginBottom: '35px',
        marginRight: 'auto',
        transition: styles.transitions.type3,
        transitionDelay: '.3s',

        '&::before, &::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: 'auto',
            right: 'auto',
            width: '100%',
            height: '50%',
            transition: styles.transitions.type3,
            transitionDelay: '0s',
        },
        '&::before': {
            transformOrigin: '100% 100%',
        },
        '&::after': {
            top: '50%',
            transformOrigin: '100% 0%',
        },
    },

    '&__item:hover &__line': {
        transform: 'translateX(100%)',
        transitionDelay: '0s',

        '&::before': {
            transform: 'rotate(-45deg) scaleX(0.5)',
            transitionDelay: '.3s',
        },
        '&::after': {
            transform: 'rotate(45deg) scaleX(0.5)',
            transitionDelay: '.3s',
        },
    },
    '&__item:hover &': {
        '&__title': {
            color: styles.colors.cyan
        },
    },

    '&._show &': {
        '&__item, &__title-wrap, &__button-wrap': {
            transition: styles.transitions.type6,
            transform: 'translateY(0)',
            opacity: '1'
        },

        '&__title-wrap': {
            transitionDelay: `${styles.blocksFadeDuration}ms`,
        },

        '&__item': {
            ...styles.setDelays({base: styles.blocksFadeDuration, count: '3', delay: '250'})
        },

        '&__button-wrap': {
            transitionDelay: `${styles.blocksFadeDuration + 1000}ms`,
        }
    },

    [styles.bp(1700)]: {
        '&__line': {
            width: '16px',
            height: '64px',
            marginBottom: '30px',
        },

        '&__list': {
            padding: '45px 0 30px'
        },

        '&__title': {
            marginBottom: '15px',
        },
    },

    [styles.bp(1300)]: {
        '&__line': {
            width: '13px',
            height: '50px',
            marginBottom: '25px',
        },

        '&__list': {
            padding: '45px 0 25px'
        },

        '&__item': {
            width: 'calc(50% - 15px)',
        },

        '&__title': {
            marginBottom: '10px',
        },
    },
    [styles.bp(1000)]: {
        '&__item, &__title-wrap, &__button-wrap': {
            transitionDelay: `0ms`,
            transform: 'translateY(100px)',
            opacity: '0'
        },

        '&._show &': {
            '&__title-wrap': {
                transitionDelay: `0ms`,
            },

            '&__item': {
                ...styles.setDelays({base: 0, count: '3', delay: '250'})
            },

            '&__button-wrap': {
                transitionDelay: `1000ms`,
            }
        },
    },

    [styles.bp(700)]: {
        '&__line': {
            width: '10px',
            height: '40px',
            marginBottom: '15px',
        },

        '&__list': {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            padding: '0'
        },

        '&__item': {
            width: '100%',
            marginBottom: '20px',

            '&:last-of-type': {
                marginBottom: '0'
            }
        }
    }
});

class LatestNews extends React.Component {
    render() {
        const { mod, data, resolution } = this.props;
        const maxNumOfNews = (resolution.isTablet || resolution.isMob) ? 2 : 3;

        return (
            <section className={`${css_style} section ${mod}`}>
                <div className="container">
                    {!resolution.isMob && data.asideText && <RotatedText text={data.asideText} mod={mod}/>}

                    <div className="inner-container">
                        <div className={`${css_style}__content`}>
                            <div className={`${css_style}__title-wrap`}>
                                {data.title &&
                                    <Title data={data.title}/>
                                }
                            </div>

                            <div className={`${css_style}__list`}>
                                {data.list &&
                                    data.list.map((item, key) => {
                                        if (key < maxNumOfNews) {
                                            return (
                                                <NavLink key={key} to={item.link} className={`${css_style}__item`}>
                                                    <div className={`${css_style}__line`}/>
                                                    <span className={`${css_style}__title`}>{require("html-react-parser")(item.title)}</span>
                                                    {item.text_block &&
                                                    <TextBlock data={item.text_block}/>
                                                    }
                                                </NavLink>
                                            )
                                        } else {
                                            return  null
                                        }
                                    })
                                }
                            </div>

                            <div className={`${css_style}__button-wrap`}>
                                {data.button_more &&
                                    <Button link={data.button_more.link} text={data.button_more.text}/>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default LatestNews;
