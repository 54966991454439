import React from "react";
import {connect} from "react-redux";
import urls from "../../constants/urls";
import Banner from "../../components/Banner";
import ProductInfo from "../../components/ProductInfo";
import ListBlock from "../../components/ListBlock";
import ContentBlock from "../../components/ContentBlock";
import ScrollContainer from "../../containers/ScrollContainer";
import Contacts from "../../components/Contacts";

import AIOverlay from "../../containers/AIOverlay";
import { ReactComponent as ProductPageSvg } from "../../images/svg/animate/product-page.svg";
import fetchQuery from "../../utils/fetch";

class KimiDesing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {},
            product_info: {},
            video_block: {},
            gallery: {},
            what_we_do: {},
            stats: {}
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.kimidesign});
    }

    render() {
        const { banner, product_info, what_we_do, stats } = this.state;
        const { resolution, location } = this.props;

        return (
            <React.Fragment>
                {!resolution.isMob &&
                    <AIOverlay startBlock="banner">
                        <ProductPageSvg/>
                    </AIOverlay>
                }

                <ScrollContainer resolution={resolution} location={location}>
                    <Banner id='banner' data={banner} overlay='2'/>
                    <ProductInfo id='product-info' data={product_info}/>
                    <ContentBlock id='content-block-1' data={stats}/>
                    <ListBlock id='use-cases' data={what_we_do}/>
                    <Contacts id='contact-us'/>
                </ScrollContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => {
    return {
        resolution: store.resolution
    }
};

export default connect(
    mapStateToProps
)(KimiDesing);
